<div class="edit-generation-unit-container">
  <h3 class="edit_gen_unit_page_title">Edit Generation Unit</h3>
  <div class="d-flex justify-content-center edit-unit-form-container">
    <div
      class="text-center"
      *ngIf="
        isGetUnitApiLoading ||
        isGetFuelSourceLoading ||
        isGetUnitTypesLoading ||
        isrebateRateChangeCodesLoading
      "
    >
      <ameren-loading></ameren-loading>
      <p>Please wait..</p>
    </div>
    <div
      class="edit_unit_api_error"
      *ngIf="
        isGetFuleSourceError ||
        isGetUnitTypesError ||
        isGetunitApiError ||
        isrebateRateChangeCodesError
      "
    >
      Something went wrong. Please try again.
    </div>
    <form
      [formGroup]="editUnitForm"
      *ngIf="
        unitTypes &&
        fuleSources &&
        !isGetUnitApiLoading &&
        !isGetunitApiError &&
        !isGetUnitTypesLoading &&
        !isrebateRateChangeCodesLoading
      "
    >
      <div class="form-group">
        <label class="required form-label fw-bold">Type:</label>

        <div *ngIf="!isOwnerTransfer">
          {{ editUnitForm?.get('type')?.value }}
        </div>

        <div *ngIf="isOwnerTransfer">
          <ng-select formControlName="type">
            <ng-option *ngFor="let type of unitTypes" [value]="type.code">
              {{ type.decode }} ({{ type.code }})
            </ng-option>
          </ng-select>
        </div>
      </div>
      <div class="form-group">
        <label
          class="form-label fw-bold"
          id="ownerName"
          for="generationOwnerName"
        >
          Generation Owner Name:
        </label>

        <div class="d-flex" *ngIf="!isOwnerTransfer">
          <span class="owner_name field_value"
            >{{ editUnitForm?.get('ownerName')?.value }} (
            {{ editUnitForm?.get('owner')?.value }}
            )</span
          >

          <button
            type="button"
            class="ms-5 float-end ameren-primary-green-btn"
            data-bs-toggle="modal"
            data-bs-target="#confirmOwnerTransfer"
            *ngIf="!disableGenUnitFields"
          >
            Transfer
          </button>
        </div>

        <div class="d-inline" *ngIf="isOwnerTransfer">
          <ng-select formControlName="owner">
            <ng-option
              *ngFor="let owners of ownerList"
              [value]="owners.ownerId"
            >
              {{ owners.name }} ({{ owners.ownerId }})
            </ng-option>
            <option [value]="">Select Generation Owner Name</option>
          </ng-select>

          <button
            type="button"
            class="ms-5 mt-3 mb-3 float-end ameren-primary-green-btn"
            (click)="toggleOwnerTransfer()"
          >
            Cancel Transfer
          </button>
        </div>

        <div
          class="text-field-error mt-3 ameren-font-sm"
          *ngIf="editUnitForm?.get('ownerName')?.invalid"
        >
          <p *ngIf="editUnitForm?.get('ownerName')?.errors?.['required']">
            Generation Owner is required.
          </p>
        </div>
      </div>
      <div class="form-group" [ngClass]="!isOwnerTransfer ? '' : 'mt-5'">
        <label class="owner_name field_label required form-label fw-bold"
          >Bill Account Number:</label
        >
        <span class="owner_name field_value ms-2">{{
          editUnitForm?.get('billAccountNumber')?.value
        }}</span>
      </div>

      <div class="form-group" *ngIf="editUnitForm?.get('type')?.value == 'COGF'">
        <label class="owner_name field_label required form-label fw-bold"
          >Location:</label
        >
        <span class="owner_name field_value ms-2">{{
          editUnitForm?.get('locationDesc')?.value
        }}</span>
      </div>

      <div class="form-group" *ngIf="showMeterNumber">
        <label class="meter_no field_label required form-label fw-bold"
          >Meter Number:</label
        >
        <span class="meter_no field_value ms-2">{{
          editUnitForm?.get('meterNo')?.value
        }}</span>
      </div>

      <div class="form-group">
        <label class="required form-label fw-bold">Capacity:</label>
        <div class="input-group">
          <input type="text" class="form-control" formControlName="capacity" />

          <div class="input-group-append">
            <span class="input-group-text ameren-margin-top">KW</span>
          </div>
        </div>
        <div
          class="field-validation-error"
          *ngIf="editUnitForm?.get('capacity')?.errors"
        >
          Capacity is required.
        </div>
      </div>
      <div class="form-group">
        <label class="required form-label fw-bold">Nick Name:</label>
        <input type="text" class="form-control" formControlName="nickName" />
        <div
          class="field-validation-error"
          *ngIf="editUnitForm?.get('nickName')?.errors"
        >
          Nick Name is required.
        </div>
      </div>
      <div class="form-group">
        <label class="required form-label fw-bold"
          >Generation Fuel Source:</label
        >
        <ng-select
          formControlName="fuelSource"
          [disabled]="disableGenUnitFields"
        >
          <ng-option *ngFor="let source of fuleSources" [value]="source.code">
            {{ source.decode }}
          </ng-option>
        </ng-select>
        <div
          class="field-validation-error"
          *ngIf="editUnitForm?.get('fuelSource')?.errors"
        >
          Generation Fuel Source is required.
        </div>
      </div>
      <div class="form-group">
        <label class="form-label fw-bold">Power Clerk Number</label>
        <input type="text" class="form-control" formControlName="powerClerk" />
      </div>
      <div class="form-group">
        <label class="form-label fw-bold">Requested Rebate</label>
        <input
          type="text"
          readonly
          class="form-control disabled"
          formControlName="rebateRequestQuantity"
        />
      </div>
      <div class="form-group">
        <label class="form-label fw-bold">Capacity for Rebate</label>
        <div class="input-group">
          <input
            type="text"
            class="form-control"
            formControlName="rebateCapacityQuantity"
            aria-describedby="rebateCapacityQuantity"
          />
          <div class="input-group-append">
            <ng-template #otherUnitType>
              <span class="input-group-text ameren-margin-top">KW</span>
            </ng-template>
            <span
              class="input-group-text ameren-margin-top"
              *ngIf="
                editUnitForm?.get('type')?.value === 'ESS';
                else otherUnitType
              "
              >KWh</span
            >
          </div>
        </div>
      </div>

      <div
        class="form-check"
        [class.disabled]="
          !editUnitForm?.get('rebateEligibleFlag')?.value ||
          !disableGenUnitFields
        "
      >
        <input
          class="form-check-input"
          type="checkbox"
          formControlName="requestRebate"
          id="rebateEligibleFlag"
        />
        <label class="form-check-label form-label fw-bold" for="requestRebate">
          Request Rebate
        </label>
      </div>
      <div class="form-group" *ngIf="enableEffectiveDate">
        <label class="form-label fw-bold">Effective Date</label>
        <input
          type="date"
          class="form-control"
          formControlName="effectiveDate"
        />
        <div
          class="field-validation-error text-danger"
          *ngIf="editUnitForm?.get('effectiveDate')?.errors"
        >
          Effective Date is required.
        </div>
      </div>

      <div
        class="form-group"
        *ngIf="
          editUnitForm?.get('requestRebate')?.value &&
          editUnitForm?.get('type')?.value == 'ESS'
        "
      >
        <label class="form-label fw-bold">Rebate Rate Change Code</label>
        <ng-select formControlName="rebateRateChangeCode">
          <ng-option *ngFor="let rc of rebateRateChangeCodes" [value]="rc.code">
            {{ rc.decode }}
          </ng-option>
        </ng-select>
        <div
          class="field-validation-error"
          *ngIf="editUnitForm?.get('rebateRateChangeCode')?.errors"
        >
          Rebate Rate Change Code is required.
        </div>
      </div>
      <div class="form-group edit_unit_btn_container d-flex">
        <button
          type="button"
          data-bs-toggle="modal"
          data-bs-target="#editGenUnitCancelModal"
          class="ameren-tertiary-green-btn"
        >
          Cancel
        </button>
        <button
          data-bs-toggle="modal"
          data-bs-target="#editGenUnitModal"
          (click)="onSaveClick()"
          type="button"
          class="mx-1 ameren-primary-green-btn"
          [disabled]="editUnitForm.invalid"
        >
          Save
        </button>
      </div>
    </form>
  </div>
</div>

<div
  class="modal fade"
  id="editGenUnitModal"
  data-bs-backdrop="static"
  data-bs-keyboard="false"
  tabindex="-1"
  aria-labelledby="staticBackdropLabel"
  aria-hidden="true"
>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title text-black" id="editGenUnitModalLabel">
          Update Generation Unit
        </h5>
        <button
          type="button"
          (click)="closeUpdateModal()"
          *ngIf="!isUpdateLoading"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body">
        <div *ngIf="isUpdateLoading" class="text-center">
          <ameren-loading></ameren-loading>
          <p>Please wait..</p>
        </div>
        <div
          class="text-danger text-center justify-content-center fw-bold mb-1"
          *ngIf="isUpdateFailure"
        >
          <p>
            <img
              class="mb-1"
              src="assets/images/failure.svg"
              alt="Failure Image"
            />
          </p>

          {{ updateFailureMessage }}
        </div>
        <div
          class="text-success text-center justify-content-center fw-bold mb-1"
          *ngIf="isUpdateSuccess"
        >
          <p>
            <img
              class="mb-1"
              src="assets/images/success.svg"
              alt="Success Image"
            />
          </p>
          {{ updateSuccessMessage }}
        </div>
      </div>
      <div class="modal-footer">
        <button
          *ngIf="!isUpdateLoading"
          type="button"
          (click)="closeUpdateModal()"
          class="btn btn-danger"
          data-bs-dismiss="modal"
        >
          Close
        </button>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade"
  id="editGenUnitCancelModal"
  data-bs-backdrop="static"
  data-bs-keyboard="false"
  tabindex="-1"
  aria-labelledby="staticBackdropLabel"
  aria-hidden="true"
>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title text-black" id="editGenUnitCancelModalLabel">
          Confirm Cancellation
        </h5>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body">
        <p>Are you sure you want to cancel?</p>
      </div>
      <div class="modal-footer">
        <a
          [routerLink]="['/generation-unit']"
          class="btn btn-success"
          data-bs-dismiss="modal"
          >Yes</a
        >

        <button
          type="button"
          data-bs-dismiss="modal"
          class="btn btn-danger"
          data-bs-dismiss="modal"
        >
          No
        </button>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade"
  id="confirmOwnerTransfer"
  data-bs-backdrop="static"
  data-bs-keyboard="false"
  tabindex="-1"
  aria-labelledby="staticBackdropLabel"
  aria-hidden="true"
>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title text-black" id="editGenUnitCancelModalLabel">
          Confirm Owner Transfer
        </h5>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body">
        <p>
          Before transferring the Generation Owner or Removing the Generation
          Unit, all active subscriptions fees must be terminated. Do you still
          want to proceed?
        </p>
      </div>
      <div class="modal-footer">
        <a
          (click)="toggleOwnerTransfer()"
          class="btn btn-success w-25"
          data-bs-dismiss="modal"
          >Yes</a
        >

        <button
          type="button"
          data-bs-dismiss="modal"
          class="btn btn-danger w-25"
          data-bs-dismiss="modal"
        >
          No
        </button>
      </div>
    </div>
  </div>
</div>
