import { Router, ActivatedRoute } from '@angular/router';
import {
  FormBuilder,
  FormGroup,
  FormControl,
  Validators,
} from '@angular/forms';
import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { GenerationUnitManagerService } from '../services/generation-unit-manager.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { GenerationOwnerManagerService } from '../../generation-owner/services/generation-owner-manager.service';
import { Subject, delay, takeUntil, tap } from 'rxjs';
import { FineGrainAuthorization } from 'src/app/shared/models/fine-grain-authorization';
import * as moment from 'moment';
import 'moment-timezone';
import { IGenerationUnit } from '../interfaces/Igeneration-unit';

@Component({
  selector: 'app-edit-generation-unit',
  templateUrl: './edit-generation-unit.component.html',
  styleUrls: ['./edit-generation-unit.component.scss'],
})
export class EditGenerationUnitComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  unitId: string;
  fuleSources: any[];
  unitTypes: any[];
  rebateRateChangeCodes: any[];

  editUnitForm: FormGroup;
  isGetUnitApiLoading: boolean = true;
  isGetFuelSourceLoading: boolean = true;
  isGetUnitTypesLoading: boolean = true;
  isGetunitApiError: boolean;
  isGetFuleSourceError: boolean;
  isGetUnitTypesError: boolean;
  isrebateRateChangeCodesLoading: boolean = true;
  isrebateRateChangeCodesError: boolean;
  isUpdateLoading: boolean;
  isUpdateFailure: boolean;
  updateFailureMessage: string;
  isUpdateSuccess: boolean;
  updateSuccessMessage: string;
  currentUser: any;
  generationUnitDetails: IGenerationUnit;

  generationOwnerListAPIFailure: boolean;
  generationOwnerListLoading: boolean = true;
  ownerList: any[];

  ownerListTemp: any[];

  viewLoaded: Subject<null>;
  destroyed$: Subject<boolean>;

  showMeterNumber: boolean;
  ownerType: string;

  isOwnerTransfer: boolean = false;
  functionalityAccess: FineGrainAuthorization;
  disableGenUnitFields: boolean = false;
  enableEffectiveDate: boolean = false;
  currentDateValue = moment().tz('America/Chicago').format('YYYY-MM-DD');

  constructor(
    private _route: ActivatedRoute,
    private genUnitMgr: GenerationUnitManagerService,
    private _fb: FormBuilder,
    private _authService: AuthService,
    private _router: Router,
    private generationOwnerManager: GenerationOwnerManagerService
  ) {
    this._route.queryParams.subscribe((data) => {
      this.unitId = data['unitid'];
    });

    this._authService.getUser().then((res) => {
      this.currentUser = res?.profile;
    });

    this.viewLoaded = new Subject();
    this.destroyed$ = new Subject();
  }

  ngOnInit(): void {
    let role = this._authService.getSelectedGenerationOwnerRole();
    let functionality = 'GENERATION_UNITS';
    this.functionalityAccess =
      this._authService.getPortalFunctioanalityAccesses(functionality, role);
    this.disableGenUnitFields = !this.functionalityAccess.isAmerenAdmin;

    this.initEditUnitForm();
    this.getFuelSources();
    this.getUnitByUnitId();
    this.getUnitTypes();
    this.getRebateRateChangeCodes();
    this.getGenerationOwnerList();

    this.viewLoaded
      .pipe(
        delay(0),
        tap(() => {
          this.listenForGenerationTypeChanges();
          this.listenForEditGenerationFormChanges();
        }),
        takeUntil(this.destroyed$)
      )
      .subscribe();
  }

  listenForEditGenerationFormChanges() {
    this.editUnitForm?.valueChanges.subscribe((val) => {
      if (val && this.editUnitForm.dirty) {
        this.enableEffectiveDate = true;
        this.editUnitForm
          ?.get('effectiveDate')
          ?.setValidators([Validators.required]);
      } else {
        this.enableEffectiveDate = false;
        this.editUnitForm?.get('effectiveDate')?.clearValidators();
      }
    });
  }

  listenForGenerationTypeChanges() {
    this.editUnitForm
      .get('type')
      ?.valueChanges.pipe(takeUntil(this.destroyed$))
      .subscribe((data: any) => {
        if (data === 'ESS' || data === 'POSG') {
          this.showMeterNumber = true;
          this.ownerType = '3';

          let genOName = document.getElementById('ownerName');
          genOName?.classList.remove('required');
          this.editUnitForm
            .get('ownerName')
            ?.removeValidators(Validators.required);
          this.editUnitForm.get('ownerName')?.clearValidators();

          this.editUnitForm.get('ownerName')?.setErrors(null);

          this.ownerList = this.ownerListTemp.filter(
            (type) => type.ownerType === '3'
          );
        } else if (data === 'CRGP' || data === 'LICS' || data === 'COGF') {
          this.showMeterNumber = false;
          this.ownerType = 'O';

          this.editUnitForm
            .get('ownerName')
            ?.addValidators(Validators.required);
          let genOName = document.getElementById('ownerName');
          genOName?.classList.add('required');

          this.ownerList = this.ownerListTemp.filter(
            (type) => type.ownerType === 'O'
          );
        } else {
          this.showMeterNumber = false;
          this.ownerType = '';
          this.ownerList = this.ownerListTemp;
        }
      });
  }

  toggleOwnerTransfer() {
    this.isOwnerTransfer = !this.isOwnerTransfer;

    if (!this.isOwnerTransfer) {
      this.getUnitByUnitId();
    }
  }

  getFuelSources() {
    this.isGetFuelSourceLoading = true;
    this.genUnitMgr.getDecodesByTableName('Fuel Source').subscribe({
      next: (data) => {
        this.isGetFuleSourceError = false;
        this.isGetFuelSourceLoading = false;

        this.fuleSources = data;
      },
      error: () => {
        this.isGetFuleSourceError = true;
        this.isGetFuelSourceLoading = false;
      },
    });
  }

  ngAfterViewInit(): void {
    this.viewLoaded.next(null);
  }

  getGenerationOwnerList() {
    this.ownerList = [];
    this.ownerListTemp = [];
    this.generationOwnerListAPIFailure = false;
    this.generationOwnerListLoading = true;
    this.generationOwnerManager.getOwnersList().subscribe({
      next: (data: any) => {
        this.generationOwnerListAPIFailure = false;
        this.generationOwnerListLoading = false;
        this.ownerList = data.sort((a: any, b: any) => {
          return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
        });
        this.ownerListTemp = data.sort((a: any, b: any) => {
          return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
        });
      },
      error: (error: any) => {
        console.log('error', error);
        this.generationOwnerListAPIFailure = true;
        this.generationOwnerListLoading = false;
      },
    });
  }

  getRebateRateChangeCodes() {
    this.isrebateRateChangeCodesLoading = true;
    this.genUnitMgr.getDecodesByTableName('CIS45862').subscribe({
      next: (data) => {
        this.isrebateRateChangeCodesLoading = false;
        this.isrebateRateChangeCodesError = false;

        this.rebateRateChangeCodes = data;
      },
      error: () => {
        this.isrebateRateChangeCodesLoading = false;
        this.isrebateRateChangeCodesError = true;
      },
    });
  }

  getUnitTypes() {
    this.isGetUnitTypesLoading = true;
    this.genUnitMgr.getDecodesByTableName('Generation Unit Type').subscribe({
      next: (data) => {
        this.isGetUnitTypesLoading = false;
        this.isGetUnitTypesError = false;
        this.unitTypes = data;
      },
      error: (err) => {
        this.isGetUnitTypesLoading = false;
        this.isGetUnitTypesError = true;
      },
    });
  }
  getUnitByUnitId() {
    this.isGetUnitApiLoading = true;
    this.genUnitMgr.getGenerationUnitByUnitId(this.unitId).subscribe({
      next: (resp) => {
        this.isGetUnitApiLoading = false;
        this.generationUnitDetails = resp;

        if (+resp.rebateCapacityQuantity <= +resp.rebateRequestQuantity) {
          resp.rebateEligibleFlag = false;
        }

        this.editUnitForm.patchValue(resp);
        this.editUnitForm.get('effectiveDate')?.setValue(this.currentDateValue);
      },
      error: (err) => {
        this.isGetUnitApiLoading = false;
        this.isGetunitApiError = true;
      },
    });
  }

  initEditUnitForm() {
    this.editUnitForm = this._fb.group({
      type: new FormControl([Validators.required]),
      ownerName: new FormControl(null),
      owner: new FormControl(),
      billAccountNumber: new FormControl(),
      capacity: new FormControl(
        { value: null, disabled: this.disableGenUnitFields },
        [Validators.required]
      ),
      nickName: new FormControl(null, [Validators.required]),
      fuelSource: new FormControl(
        { value: null, disabled: this.disableGenUnitFields },
        [Validators.required]
      ),
      powerClerk: new FormControl({
        value: null,
        disabled: this.disableGenUnitFields,
      }),
      rebateRequestQuantity: new FormControl(null, [Validators.required]),
      rebateCapacityQuantity: new FormControl({
        value: 0,
        disabled: this.disableGenUnitFields,
      }),
      rebateEligibleFlag: new FormControl(false),
      requestRebate: new FormControl({
        value: false,
        disabled: this.disableGenUnitFields,
      }),
      effectiveDate: new FormControl(),
      rebateRateChangeCode: new FormControl(),
      unitId: new FormControl(),
      meterNo: new FormControl(),
      status: new FormControl(),
      locationID: new FormControl(),
      locationDesc: new FormControl(),
      totalSubscriberUsage: new FormControl(),
      subscribedAllocation: new FormControl(),
    });
  }

  onSaveClick() {
    let model = this.editUnitForm.value;

    let unitBody;
    if (this.disableGenUnitFields) {
      unitBody = {
        subscribedAllocation: +this.generationUnitDetails.totalSubscriberUsage,
        rebateCapacityQuantity:
          +this.generationUnitDetails.rebateCapacityQuantity,
        rebateRequestQuantity:
          +this.generationUnitDetails.rebateRequestQuantity,
        requestRebate: model.requestRebate || false,
        capacity: +this.generationUnitDetails.capacity,
        owner: this.generationUnitDetails.owner,
        meterNo: this.generationUnitDetails.meterNo,
        effectiveDate: model.effectiveDate || '',
        billAccountNumber: this.generationUnitDetails.billAccountNumber,
        powerClerk: this.generationUnitDetails.powerClerk,
        fuelSource: this.generationUnitDetails.fuelSource,
        rebateRateChangeCode: this.generationUnitDetails.rebateRateChangeCode
          ? this.generationUnitDetails.rebateRateChangeCode
          : '',
        nickName: model.nickName,
        unitId: this.generationUnitDetails.unitId,
        type: this.generationUnitDetails.type,
        audit: this.currentUser?.email,
      };
    } else {
      unitBody = {
        subscribedAllocation: +this.generationUnitDetails.totalSubscriberUsage,
        rebateCapacityQuantity: +model.rebateCapacityQuantity,
        rebateRequestQuantity: +model.rebateRequestQuantity,
        requestRebate: model.requestRebate,
        capacity: +model.capacity,
        owner: this.editUnitForm.controls['owner'].value || '',
        meterNo: model.meterNo,
        effectiveDate: model.effectiveDate || '',
        billAccountNumber: model.billAccountNumber,
        powerClerk: model.powerClerk,
        fuelSource: model.fuelSource,
        rebateRateChangeCode: model.rebateRateChangeCode || '',
        nickName: model.nickName,
        unitId: model.unitId,
        type: this.editUnitForm.controls['type'].value,
        audit: this.currentUser?.email,
      };
    }

    this.isUpdateLoading = true;

    this.genUnitMgr.updateGenUnit(unitBody).subscribe({
      next: (resp) => {
        this.isUpdateLoading = false;
        this.isUpdateFailure = false;
        this.isUpdateSuccess = true;
        this.updateSuccessMessage = resp.successMessage;
      },
      error: (err) => {
        if (err && err.status == 504) {
          this.isUpdateFailure = false;
          this.isUpdateSuccess = true;
          this.updateSuccessMessage =
            "Your change was successful. The system is now processing your request. If you check back later and do not see your changes, please contact RenewablesIllinois@ameren.com";
        } else {
          this.updateFailureMessage = err?.error?.errorDetails?.message;
          if (!this.updateFailureMessage)
            this.updateFailureMessage =
              "Something went wrong. Please try again.";
          this.isUpdateLoading = false;
          this.isUpdateFailure = true;
        }
      },
    });
  }

  closeUpdateModal() {
    if (this.isUpdateSuccess) {
      this._router.navigate(['generation-unit']);
    } else {
      this.updateFailureMessage = '';
      this.isUpdateFailure = false;
    }
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.unsubscribe();
  }
}
