import { ReportsManagerService } from './../services/reports-manager.service';
import { ExportUtility } from './../../../shared/utilities/utility.export';
import { GenerationOwnerManagerService } from './../../generation-owner/services/generation-owner-manager.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-gen-unit-subscriber-contract-report',
  templateUrl: './gen-unit-subscriber-contract-report.component.html',
  styleUrls: ['./gen-unit-subscriber-contract-report.component.scss']
})
export class GenUnitSubscriberContractReportComponent implements OnInit {

  constructor(private genOwnerMgr: GenerationOwnerManagerService,
    private _export: ExportUtility,
    private reportMgr: ReportsManagerService) { }

  genOwners: any[];
  selectedOwner: any;
  cssDate: string;
  minDate: string;
  maxDate: string;
  reportData: any[];
  units: any[];
  selectedUnit: any;
  isApiLoading: boolean;
  isReportLoading: boolean;
  searchClicked: boolean;
  dtBegin: string;
  dtEnd: string;
  integrationErrorMsg:any;

  ngOnInit(): void {
    this.getOwners();
  }

  getOwners() {
    this.genOwnerMgr.getAllOwners().subscribe({
      next: (resp) => {
        this.genOwners = resp?.sort((a: any, b: any) => {
          return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
        });
      },
    });
  }

  onOwnerChange() {
    this.selectedUnit = undefined;
    this.getUnitsByOwner();
  }
 getUnitsByOwner() {
    this.integrationErrorMsg = "";
    this.isApiLoading = true;
    this.genOwnerMgr
      .getGenerationUnitsByOwnerId(this.selectedOwner.ownerId)
      .subscribe({
        next: (resp) => {        
          this.isApiLoading = false;         
          if(resp)
          {
            this.units = resp;
            if (this.units.length == 1) {
              this.selectedUnit = this.units[0];
          }
        }
        else
          this.integrationErrorMsg = resp?.errorDetails ? resp?.errorDetails?.message : 'Sorry, something went wrong. Please try again later.';
        },        
      error: (err) => {
        this.isApiLoading = false;
        this.units = []; 
        this.integrationErrorMsg = err.error?.errorDetails ? err.error.errorDetails?.message : err.errorDescription; 
      },
      });
  }
  search() {
    this.searchClicked = true;
    this.isReportLoading = true;
    this.reportMgr
      .getGenUnitSubscriberContractReport(
        this.dtBegin,
        this.dtEnd,
        this.selectedOwner.ownerId,
        this.selectedUnit.unitId || "0"
      )
      .subscribe({
        next: (resp) => {
          
          this.isReportLoading = false;
          this.reportData = resp;
        },
      });
  }

  exportReport() {
    let keyHeaders: string[] = [];
    let modifiedlist: any = [];
    let displayHeaders: string[] = [
      "Subscriber Name",
      "Subscriber Account Number",
      "Subscriber Identification Number",
      "Subscribed KW",
      "Active Date",
      "Termination Date",
      "DS Rate Class"
    ];

    keyHeaders = [
      "subscriberName",
      "billAccountNumber",
      "customerIdentifier",
      "subscribeQuantity",
      "effectiveDate",
      "terminationDate",
      "rateClassDesc",
    ];

    this.reportData.forEach((row: any) => {
      let temp: any = {};

      temp["subscriberName"] = row.subscriberName;
      temp["billAccountNumber"] = row.billAccountNumber;
      temp["customerIdentifier"] = row.customerIdentifier;
      temp["subscribeQuantity"] = row.subscribeQuantity;
      temp["effectiveDate"] = row.effectiveDate;

      temp["terminationDate"] = row.terminationDate;
      temp["rateClassDesc"] = row.rateClassDesc;
      modifiedlist.push(temp);
    });
    this._export.exportJsonToExcelFile(
      modifiedlist,
      "generation unit subscriber contract report",
      false,
      keyHeaders,
      displayHeaders
    );
  }

}
