<div class="gen-owner-container justify-content-center">
  <div class="generation_owner_page_header">Generation Owner test</div>
  <div class="col-md-12">
    <em>
      Disclaimer: COGF Participating Customers will be referred to throughout this portal as Subscribers.
    </em>
  </div>
  <div class="row gen_owner_search_container mt-5">
    <div class="col-md-1"></div>
    <div class="col-md-2">
      <div class="form-check">
        <input
          class="form-check-input"
          type="checkbox"
          value=""
          id="inactiveOwners"
          [checked]="includeInactiveOwners"
          (click)="onToggleActiveInactive()"
        />
        <label class="form-check-label" for="inactiveOwners">
          Show Inactive Owners
        </label>
      </div>
    </div>
    <div class="col-md-2">
      <select
        (change)="onOwnerTypeChange()"
        [(ngModel)]="ownerType"
        class="form-select"
        aria-label="Default select select-owner-type"
      >
        <option value="-1"></option>
        <option value="O">Off Site</option>
        <option value="3">3rd Party</option>
      </select>
    </div>
    <div class="col-md-4">
      <div class="d-flex flex-row gen_owner__search">
        <div class="input-group search_button searchuser mb-3 ms-auto">
          <input
            type="search"
            class="form-control mt-0"
            placeholder="Search"
            aria-label="searchAmerenUser"
            [(ngModel)]="searchString"            
            (ngModelChange)="searchFilter()"
            aria-describedby="searchAmerenUser"
          />
          <span
            class="input-group-text"
            id="searchAmerenUser"
            (click)="searchFilter()"
            ><em class="fa fa-search"></em
          ></span>
        </div>
      </div>
    </div>

    <div class="col-md-3">
      <button
        class="btn btn-outline-secondary gen_owner_export"
        (click)="exportgenOwners()"
      >
        <img
          src="assets/icon/excelicon.svg"
          alt="CalendarImage"
          width="22"
          height="19"
        />
        Export
      </button>
      <button
        [routerLink]="['/add-generation-owner']"
        type="button"
        class="btn btn-success add-generation-owner-btn"
        *ngIf="functionalityAccess.isAmerenAdmin"
      >
        Add Generation Owner
      </button>
    </div>
  </div>
  <div class="col-md-12 mt-5">
    <mat-paginator
      *ngIf="owners && owners.length > 9"
      #paginator
      [pageSize]="pageSize"
      [pageSizeOptions]="[10]"
      [showFirstLastButtons]="true"
      [length]="totalSize"
      [pageIndex]="currentPage"
      (page)="handlePage($event)"
    >
    </mat-paginator>
    <table class="table gen_owner_grid" aria-describedby="Generation owner">
      <thead>
        <tr>
          <th scope="col">
            <span class="table-head" [appSort]="owners" (sortComplete)="onSort()" data-order="asc" data-name="ownerId">Owner ID <em
              class="fa-solid fa-sort"></em></span>            
          </th>
          <th scope="col">
             <span class="table-head" [appSort]="owners" (sortComplete)="onSort()" data-order="asc" data-name="name">Name <em
              class="fa-solid fa-sort"></em></span>            
          </th>
          <th scope="col" *ngIf="ownerType != 'O'">
             <span class="table-head" [appSort]="owners" (sortComplete)="onSort()" data-order="asc" data-name="ownerBillAccount">Bill Account Number <em
              class="fa-solid fa-sort"></em></span>            
          </th>
          <th scope="col">Contact Information </th>
          <th scope="col">Address </th>
          <th scope="col">
            <span class="table-head" [appSort]="owners" (sortComplete)="onSort()" data-order="asc" data-name="generatingUnitsQuantity">Number of Generation Units <em
              class="fa-solid fa-sort"></em></span>            
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td id="gen_owner_loading" colspan="6" *ngIf="isApiLoading">
            <div class="text-center">
              <p>Please wait..</p>
              <ameren-loading></ameren-loading>
            </div>
          </td>
          <td
            colspan="6"
            id="gen_owner_no_data"
            *ngIf="!isApiLoading && !apiError && (!owners || !owners.length)"
          >
            <div class="text-center">No data</div>
          </td>
          <td
            colspan="6"
            id="gen_owner_api_error"
            *ngIf="!isApiLoading && apiError"
          >
            <div class="text-center text-danger">
              Something went wrong. Please try again.
            </div>
          </td>
        </tr>
        <tr *ngFor="let owner of pagedOwners">
          <td>{{ owner.ownerId }}</td>
          <td>
            <a
              class="gen_owner_link"
              [routerLink]="['/generation-owner/owner-dashboard']"
              [queryParams]="{ ownerId: owner.ownerId }"
              >{{ owner.name }}</a
            >
          </td>
          <td *ngIf="ownerType != 'O'">{{ owner.ownerBillAccount }}</td>
          <td>
            <div class="gen_owner_contact">
              <ul>
                <li>Contact Type: {{ owner.contact.contactTypeDecode }}</li>
                <li>Name: {{ owner.contact.contactPerson }}</li>
                <li>Phone: {{ owner.contact.contactPhone }}</li>
                <li>Email: {{ owner.contact.email }}</li>
              </ul>
            </div>
          </td>
          <td>
            <div class="gen_owner_contact gen_owner_contact_address">
              <ul>
                <li>
                  {{ owner.contact.mailingAddress.addressLine1 }}
                  {{ owner.contact.mailingAddress.addressLine2 }}
                </li>
                <li>
                  {{ owner.contact.mailingAddress.city }}
                  {{ owner.contact.mailingAddress.state }}
                  {{ owner.contact.mailingAddress.postalCode }}
                  {{ owner.contact.mailingAddress.country }}
                </li>
              </ul>
            </div>
          </td>
          <td>
            {{ owner.generatingUnitsQuantity }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
