import { isNumber } from 'lodash';
import { map } from 'rxjs/operators';
import { of } from 'rxjs';
import { Observable } from 'rxjs';
import { CorrelationService } from 'src/app/shared/services/correlation.service';
import { HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { data } from 'jquery';
import { IGlobalAPIResponse } from 'src/app/shared/interfaces/iglobal-api-response';
import { IRates } from '../interfaces/Irates';

@Injectable({
  providedIn: 'root',
})
export class GenerationUnitApiService {
  constructor(
    private http: HttpClient,
    private correlationService: CorrelationService
  ) {}

  getGenerationUnitByUnitId(unitId: string): Observable<any> {
    const headers = new HttpHeaders()
      .set('amrnenv', `${environment.cssEnv}`)
      .set('vpcenv', `${environment.environmentName}`)
      .set('x-modified-by-user-id', 'ANM')
      .set('domain', 'private')
      .set('state', 'ILLINOIS')
      .set('X-Correlation-Id', this.correlationService.getCorrelationId());

    return this.http.get<IGlobalAPIResponse>(
      `${environment.apig}/genunits/byunitid?unitid=${unitId.toString()}`,
      { headers }
    );
  }

  getDecodes(): Observable<any> {
    if (!localStorage.getItem('decodes')) {
      const headers = new HttpHeaders()
        .set('amrnenv', `${environment.cssEnv}`)
        .set('vpcenv', `${environment.environmentName}`)
        .set('x-modified-by-user-id', 'ANM')
        .set('domain', 'private')
        .set('X-Correlation-Id', this.correlationService.getCorrelationId());

      return this.http
        .get<IGlobalAPIResponse>(`${environment.apig}/decodes`, {
          headers,
        })
        .pipe(
          map((res: IGlobalAPIResponse) => {
            if (res.success) {
              localStorage.setItem('decodes', JSON.stringify(res));
              return res;
            }
          })
        );
    } else {
      return of(JSON.parse(localStorage.getItem('decodes') || ''));
    }
  }

  updateGenUnit(genUnit: any): Observable<any> {
    const headers = new HttpHeaders()
      .set('amrnenv', `${environment.cssEnv}`)
      .set('vpcenv', `${environment.environmentName}`)
      .set('x-modified-by-user-id', 'ANM')
      .set('domain', 'private')
      .set('state', 'ILLINOIS')
      .set('Content-Type', 'application/json')
      .set('audit', genUnit.audit)
      .set('X-Correlation-Id', this.correlationService.getCorrelationId());

    return this.http.put<IGlobalAPIResponse>(
      `${environment.apig}/genunits?unitid=${genUnit.unitId.toString()}`,
      genUnit,
      { headers }
    );
  }

  getGenerationUnits(page?: number): Observable<any> {
    const headers = new HttpHeaders()
      .set('amrnenv', `${environment.cssEnv}`)
      .set('x-modified-by-user-id', 'ANM')
      .set('state', 'ILLINOIS')
      .set('page', isNumber(page) ? page.toString() : '')
      .set('X-Correlation-Id', this.correlationService.getCorrelationId());

    return this.http.get<IGlobalAPIResponse>(
      `${environment.cloudHub}/${
        isNumber(page)
          ? 'metering-aggregated-reports-e/v1/generatingUnits/pagination'
          : 'metering-aggregated-reports-e/v1/generatingUnits'
      }`,
      {
        headers,
      }
    );
  }

  getGenerationUnitRateHistory(unitId: any): Observable<any> {
    const headers = new HttpHeaders()
      .set('amrnenv', `${environment.cssEnv}`)
      .set('vpcenv', `${environment.environmentName}`)
      .set('x-modified-by-user-id', 'ANM')
      .set('domain', 'private')
      .set('state', 'IL')
      .set('X-Correlation-Id', this.correlationService.getCorrelationId());

    return this.http.get<IGlobalAPIResponse>(
      `${environment.apig}/genunits/ratehistory?unitid=${unitId}`,
      {
        headers,
      }
    );
  }

  submitSubscriptionFee(body: IRates, unitId: any, isinternaluser: string) {
    const headers = new HttpHeaders()
      .set('amrnenv', `${environment.cssEnv}`)
      .set('vpcenv', `${environment.environmentName}`)
      .set('x-modified-by-user-id', 'ANM')
      .set('domain', 'private')
      .set('state', 'IL')
      .set('X-Correlation-Id', this.correlationService.getCorrelationId())
      .set('audit', 'audit')
      .set('isinternaluser', isinternaluser);

    return this.http.post<IGlobalAPIResponse>(
      `${environment.apig}/genunits/${unitId}/rates`,
      body,
      { headers }
    );
  }

  getSubscribersByBillAccount(
    acct: string,
    unitId: string,
    ownerId: string
  ): Observable<any> {
    const headers = new HttpHeaders()
      .set('amrnenv', `${environment.cssEnv}`)
      .set('vpcenv', `${environment.environmentName}`)
      .set('x-modified-by-user-id', 'ANM')
      .set('domain', 'private')
      .set('state', 'IL')
      .set('ownerid', ownerId.toString())
      .set('unitid', unitId.toString())
      .set('billaccountnumber', acct.toString())
      .set('X-Correlation-Id', this.correlationService.getCorrelationId());

    return this.http.get<IGlobalAPIResponse>(
      `${environment.apig}/subscribers/bybillaccount`,

      {
        headers,
      }
    );
  }

  getSubscribers(
    acct?: string,
    unitId?: string,
    ownerId?: string,
    begindate?: string,
    enddate?: string
  ): Observable<any> {
    const headers = new HttpHeaders()
      .set('amrnenv', `${environment.cssEnv}`)
      .set('x-modified-by-user-id', 'ANM')
      .set('state', 'IL')
      .set('X-Correlation-Id', this.correlationService.getCorrelationId());

    ownerId = ownerId ? ownerId.toString() : '0';
    unitId = unitId ? unitId.toString() : '0';
    acct = acct ? acct.toString() : '0';
    begindate = begindate ? begindate.toString() : '';
    enddate = enddate ? enddate.toString() : '';

    return this.http.get<IGlobalAPIResponse>(
      `${environment.cloudHub}/metering-aggregated-reports-e/v1/subscribers?ownerId=${ownerId}&unitId=${unitId}&billAccountNumber=${acct}&beginDate=${begindate}&endDate=${enddate}`,

      {
        headers,
      }
    );
  }

  removeSubscribers(subscribers: any[], audit: string): Observable<any> {
    const headers = new HttpHeaders()
      .set('amrnenv', `${environment.cssEnv}`)
      .set('vpcenv', `${environment.environmentName}`)
      .set('x-modified-by-user-id', 'ANM')
      .set('domain', 'private')
      .set('state', 'ILLINOIS')
      .set('Content-Type', 'application/json')
      .set('audit', audit)
      .set('X-Correlation-Id', this.correlationService.getCorrelationId());

    const options = {
      headers: headers,
      body: subscribers,
    };
    return this.http.delete<IGlobalAPIResponse>(
      `${environment.apig}/subscribers`,
      options
    );
  }

  getCSSDate(): Observable<any> {
    const headers = new HttpHeaders()
      .set('amrnenv', `${environment.cssEnv}`)
      .set('vpcenv', `${environment.environmentName}`)
      .set('x-modified-by-user-id', 'ANM')
      .set('domain', 'private')
      .set('X-Correlation-Id', this.correlationService.getCorrelationId());

    return this.http.get<IGlobalAPIResponse>(
      `${environment.apig}/cssdate`,

      {
        headers,
      }
    );
  }

  addGenerationUnit(addGenerationUnitBody: any): Observable<any> {
    const headers = new HttpHeaders()
      .set('amrnenv', `${environment.cssEnv}`)
      .set('vpcenv', `${environment.environmentName}`)
      .set('x-modified-by-user-id', 'ANM')
      .set('domain', 'private')
      .set('Content-Type', 'application/json')
      .set('audit', 'audit')
      .set('X-Correlation-Id', this.correlationService.getCorrelationId());

    return this.http.post<IGlobalAPIResponse>(
      `${environment.apig}/genunits`,
      addGenerationUnitBody,
      { headers }
    );
  }
  getRebates(genOwnerId: any): Observable<any> {
    const headers = new HttpHeaders()
      .set('amrnenv', `${environment.cssEnv}`)
      .set('x-modified-by-user-id', 'ANM')
      .set('owner', genOwnerId.toString())
      .set('state', 'IL');
    return this.http.get<IGlobalAPIResponse>(
      `${environment.cloudHub}/metering-aggregated-reports-e/v1/generatingUnits/withRebates`,
      {
        headers,
      }
    );
  }
}
