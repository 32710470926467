import { Component, OnInit, ViewChild } from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { GenerationOwnerManagerService } from "../services/generation-owner-manager.service";
import { AuthService } from "src/app/shared/services/auth.service";
import { FineGrainAuthorization } from 'src/app/shared/models/fine-grain-authorization';

@Component({
  selector: 'app-select-generation-owner',
  templateUrl: './select-generation-owner.component.html',
  styleUrls: ['./select-generation-owner.component.scss']
})
export class SelectGenerationOwnerComponent implements OnInit {

  constructor(
    private generationOwnerMgrService: GenerationOwnerManagerService, private authService: AuthService) {
      this.authService.setSelectedGenerationOwner(null);    
    }

  @ViewChild(MatPaginator) paginator: MatPaginator;
  allOwners: any[];
  owners: any[];
  searchString: string;
  ownerType: string = "O";
  includeInactiveOwners: boolean;
  isApiLoading: boolean;
  apiError: boolean;
  integrationErrorMsg:any;
  currentUser: any;
  loggedInUser:any;
  isInternalUser:boolean = false;
  functionalityAccess: FineGrainAuthorization;

  ngOnInit(): void {
    this.loggedInUser = this.authService.getCurrentUser(); 

    if (this.loggedInUser != null)
      this.currentUser = this.loggedInUser.profile;
      
      if(this.currentUser)
      {
        this.currentUser.anmRoleMap = this.currentUser?.anmRoleMap?.filter(
          (lst:any, i:any, arr:any) => arr.findIndex((t:any) => t.generationOwner === lst.generationOwner && t.status === 'Active') === i
        ); 
      }

      let internal_roles = this.currentUser?.anmRoleMap.filter(
        (a:any) =>
        (a.role != 'anmExternalAdmin' && a.role != 'anmExternalViewOnly')  && (a.status === 'Active')
      );     

      this.isInternalUser = internal_roles[0] != undefined && internal_roles?.length >= 0;
      let roles =  internal_roles.map((x:any) => x.role);           

     if (this.isInternalUser)
      this.authService.setSelectedGenerationOwnerRole(roles);

      this.functionalityAccess = this.authService.getPortalFunctioanalityAccesses('GENERATION_OWNERS', roles);

    this.getAllOwners();
  }

  getAllOwners() {
    this.allOwners = [];
    this.owners = [];
    this.isApiLoading = true;
    this.integrationErrorMsg = "";
    let genOwners =  this.currentUser.anmRoleMap ? this.currentUser.anmRoleMap.filter((a:any) =>
    (a.role === 'anmExternalAdmin' || a.role === 'anmExternalViewOnly' ) && a.status === 'Active').map((x:any) => x.generationOwner).join(',') : undefined ;

    this.generationOwnerMgrService.getAllOwners().subscribe({
      next: (data) => {
        this.apiError = false;
        this.allOwners = data;

      if (genOwners) 
        {
          this.allOwners = this.allOwners?.filter(
            (a:any) =>
            (genOwners?.includes(a.ownerId)) 
          );
        } 
        else{
          this.isApiLoading =false;
          this.apiError = true;      
          this.integrationErrorMsg = "You are not authorized to access this functiaonlity!";
        }       

        this.isApiLoading = false;

        this.allOwners.forEach((owner: any) => {
          owner.status =
            owner.generatingUnitsQuantity == "0" ? "Inactive" : "Active";
         
        });
      

        this.owners = genOwners? this.allOwners : [];
       
      },
      error: (error) => {
        this.apiError = false;
        this.isApiLoading = false;
        this.integrationErrorMsg =  error.error?.errorDetails ? error.error.errorDetails?.message : error.errorDescription;  
      },
    });
  }

}
