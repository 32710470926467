import { RebateManagerService } from './../services/rebate-manager.service';
import { ExportUtility } from 'src/app/shared/utilities/utility.export';
import { MatPaginator } from '@angular/material/paginator';
import { GenerationUnitManagerService } from './../../generation-unit/services/generation-unit-manager.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { AuthService } from 'src/app/shared/services/auth.service';
import { FineGrainAuthorization } from 'src/app/shared/models/fine-grain-authorization';
import { ProfileManagerService } from 'src/app/shared/services/profilemanager.service';

@Component({
  selector: 'app-manage-rebate',
  templateUrl: './manage-rebate.component.html',
  styleUrls: ['./manage-rebate.component.scss'],
})
export class ManageRebateComponent implements OnInit {
  constructor(
    private _genUnitMgr: GenerationUnitManagerService,
    private _export: ExportUtility,
    private _authService: AuthService,
    private _rebateSvc: RebateManagerService,
    private profileManager: ProfileManagerService
  ) {}
  rebates: any[];
  isRebatesApiLoading: boolean;
  isRebatesApiError: boolean;
  searchString: string;
  showinactiveRebates: boolean;
  allRebates: any[];
  isRebateApprover: boolean = false;

  pageSize = 10;
  currentPage = 0;
  totalSize = 0;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  pagedRebates: any[];
  functionalityAccess: FineGrainAuthorization;
  selectedGenerationOwner: any;
  hasSelectedGenerationOwner: boolean = false;
  loggedInUser: any;
  currentUser: any;
  isInternalUser: boolean = false;

  ngOnInit(): void {
    this.loggedInUser = this._authService.getCurrentUser();

    if (this.loggedInUser != null) this.currentUser = this.loggedInUser.profile;

    this.currentUser.anmRoleMap = this.currentUser?.anmRoleMap.filter(
      (lst: any, i: any, arr: any) =>
        arr.findIndex(
          (t: any) =>
            t.generationOwner === lst.generationOwner && t.status === 'Active'
        ) === i
    );
    let internal_roles = this.currentUser?.anmRoleMap.filter(
      (a: any) =>
        a.role != 'anmExternalAdmin' &&
        a.role != 'anmExternalViewOnly' &&
        a.status === 'Active'
    );

    this.isInternalUser =
      internal_roles[0] != undefined && internal_roles?.length >= 0;
    let roles = internal_roles.map((x: any) => x.role);

    if (this.isInternalUser)
      this._authService.setSelectedGenerationOwnerRole(roles);

    this.selectedGenerationOwner =
      this._authService.getSelectedGenerationOwner();

    this.isRebateApprover =
      this.profileManager.profile[0].isRebateApprover || false;

    this.hasSelectedGenerationOwner = this.selectedGenerationOwner
      ? true
      : false;
    this.getRebates();
  }

  getRebates() {
    this.isRebatesApiLoading = true;
    let genOwnerId = 0;
    if (this.hasSelectedGenerationOwner) genOwnerId = this.selectedGenerationOwner?.ownerId;

    this._genUnitMgr.getRebates(genOwnerId).subscribe({
      next: (resp) => {
        this.isRebatesApiLoading = false;
        this.allRebates = resp;
        this.rebates = this.allRebates.filter((x) => x.status == '2');

        let role = this._authService.getSelectedGenerationOwnerRole();
        let functionality = 'REBATE';
        this.functionalityAccess =
          this._authService.getPortalFunctioanalityAccesses(
            functionality,
            role
          );

        if (this.isRebateApprover) {
          this.rebates = this.allRebates.filter(
            (x: any) => x.waitingForApprovalFlag
          );
        }

        this.iterator();
      },
      error: (err) => {
        this.isRebatesApiError = true;
        this.isRebatesApiLoading = false;
      },
    });
  }

  onSort() {
    this.iterator();
  }
  exportRebates() {
    let keyHeaders: string[] = [];
    let modifiedlist: any = [];
    let displayHeaders: string[] = [
      'Owner Name',
      'Unit ID',
      'Bill Account Number',
      'Nick Name',
      'Rebate Capacity',
      'Rebate Request',
      'Rebate Eligible',
      'Rebate Not Requested',
    ];

    keyHeaders = [
      'ownerName',
      'unitId',
      'billAccountNumber',
      'nickName',
      'rebateCapacityQuantity',
      'rebateRequestQuantity',
      'rebateEligibleQuantity',
      'rebateNotSubscribedQuantity',
    ];

    this.rebates.forEach((row: any) => {
      let temp: any = {};

      temp['ownerName'] = row.ownerName;
      temp['unitId'] = row.unitId;
      temp['billAccountNumber'] = row.billAccountNumber;
      temp['nickName'] = row.nickName;
      temp['rebateCapacityQuantity'] = row.rebateCapacityQuantity;
      temp['rebateRequestQuantity'] = row.rebateRequestQuantity;
      temp['rebateEligibleQuantity'] = row.rebateEligibleQuantity;
      temp['rebateNotSubscribedQuantity'] = row.rebateNotSubscribedQuantity;

      modifiedlist.push(temp);
    });
    this._export.exportJsonToExcelFile(
      modifiedlist,
      'rebates',
      false,
      keyHeaders,
      displayHeaders
    );
  }
  searchFilter() {
    if (this.showinactiveRebates)
      this.rebates = this.allRebates.filter((z) => (z.status! = '2'));
    else this.rebates = this.allRebates.filter((x) => x.status == '2');

    if (this.hasSelectedGenerationOwner)
      this.rebates = this.allRebates.filter(
        (x) => x.owner == this.selectedGenerationOwner?.ownerId
      );
    else this.rebates = this.allRebates;

    if (this.isRebateApprover) {
      this.rebates = this.allRebates.filter(
        (x: any) => x.waitingForApprovalFlag
      );
    }

    let originalRebates: any[] = this.rebates;

    if (this.searchString && this.searchString.trim() != '') {
      let adjustedSearch = this.searchString.trim().toLocaleLowerCase();
      this.rebates = originalRebates.filter(
        (data) =>
          data.ownerName?.toLocaleLowerCase()?.match(adjustedSearch) ||
          data.unitId?.match(adjustedSearch) ||
          data.billAccountNumber?.match(adjustedSearch) ||
          data.nickName?.toLocaleLowerCase()?.match(adjustedSearch) ||
          data.rebateCapacityQuantity?.match(adjustedSearch) ||
          data.rebateRequestQuantity?.match(adjustedSearch) ||
          data.rebateEligibleQuantity?.match(adjustedSearch) ||
          data.statusDescription?.toLocaleLowerCase()?.match(adjustedSearch) ||
          data.rebateNotSubscribedQuantity?.match(adjustedSearch)
      );
    } else {
      this.rebates = originalRebates;
    }

    this.iterator();
  }
  handlePage(e: any) {
    this.currentPage = e.pageIndex;
    this.pageSize = e.pageSize;
    this.iterator();
  }
  private iterator() {
    this.totalSize = this.rebates.length;
    if (this.totalSize > this.pageSize) {
      const end = (this.currentPage + 1) * this.pageSize;
      const start = this.currentPage * this.pageSize;
      this.pagedRebates = this.rebates.slice(start, end);
    } else {
      this.pagedRebates = this.rebates;
    }
  }
  onToggleActiveInactive() {
    if (this.hasSelectedGenerationOwner)
      this.rebates = this.allRebates.filter(
        (x) => x.owner == this.selectedGenerationOwner?.ownerId
      );
    else this.rebates = this.allRebates;

    if (this.isRebateApprover) {
      this.rebates = this.allRebates.filter(
        (x: any) => x.waitingForApprovalFlag
      );
    }

    this.showinactiveRebates = !this.showinactiveRebates;
    if (this.showinactiveRebates)
      this.rebates = this.rebates.filter((x) => x.status != '2');
    else this.rebates = this.rebates.filter((x) => x.status == '2');
    this.iterator();
  }
}
