import { ReportsManagerService } from './../services/reports-manager.service';
import { ExportUtility } from './../../../shared/utilities/utility.export';
import { GenerationOwnerManagerService } from "./../../generation-owner/services/generation-owner-manager.service";
import { Component, OnInit } from "@angular/core";
import { GenerationUnitManagerService } from '../../generation-unit/services/generation-unit-manager.service';

@Component({
  selector: "app-gen-unit-sub-billaccount-report",
  templateUrl: "./gen-unit-sub-billaccount-report.component.html",
  styleUrls: ["./gen-unit-sub-billaccount-report.component.scss"],
})
export class GenUnitSubBillaccountReportComponent implements OnInit {
  constructor(
    private genOwnerMgr: GenerationOwnerManagerService,
    private genUnitMgr: GenerationUnitManagerService,
    private reportMgrSvc: ReportsManagerService ,
    private _export: ExportUtility
  ) {}

  subscribers: any[];
  allSubscribers: any[];
  meters: any[];
  allOwners: any[];

  owners: any[];

  selectedAccount: any;
  selectedOwner: any;
  selectedMeter: any;

  isReportCrieteriaLoading: boolean;
  dtBegin: string;
  dtEnd:string;
  integrationErrorMsg:any;
  isAPIFailure:boolean = false;
  startDates: any[]=[];
  endDates: any[]=[];
  searchClicked: boolean;
  reportData: any[];
  isReportLoading: boolean;
  ngOnInit(): void {
    this.getGenOwners();
  }

  getGenOwners() {
    this.genOwnerMgr.getAllOwners().subscribe({
      next: (resp: any) => {
        this.allOwners = resp;
        this.owners = this.allOwners?.sort((a: any, b: any) => {
          return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
        });
      },
    });
  }

  onOwnerChange(){
    this.subscribers = [];
    this.allSubscribers = [];
    this.reportData = [];
    this.startDates = [];
    this.endDates = [];
    this.meters = [];
    this.dtBegin = "";
    this.dtEnd = "";

    this.genUnitMgr.getSubscribers('0', '0', this.selectedOwner.ownerId).subscribe({
      next: (resp: any) => {
        this.allSubscribers = resp?.filter(
          (s:any, index:number, self:any[]) => index === self.findIndex((t) => (t.billAccountNumber === s.billAccountNumber && t.meterNo === s.meterNo))
        ); 
        this.subscribers = resp?.filter(
          (s:any, index:number, self:any[]) => index === self.findIndex((t) => (t.billAccountNumber === s.billAccountNumber))
        );       
      }
    });
  }

  onBillAccountChange() {   
    this.reportData = [];
    this.startDates = [];
    this.endDates = [];
    this.meters = [];
    this.dtBegin = "";
    this.dtEnd = "";
     
    this.meters = this.allSubscribers.filter(
      (x) => x.billAccountNumber == this.selectedAccount.billAccountNumber
    ); 

    if (this.meters.length == 1)
    {
       this.selectedMeter = this.meters[0];

       if (this.selectedMeter)
          this.onMeterChange();
    }
  }
  onMeterChange() {
    this.isReportCrieteriaLoading = true;
    this.isAPIFailure = false;
    this.integrationErrorMsg = "";
    
    this.reportMgrSvc.getBillPeriods(this.selectedAccount.billAccountNumber,this.selectedMeter?.servicePt).subscribe({
      next: (resp)=>{
        this.isReportCrieteriaLoading = false;
        resp.forEach((elm:any) => {
          this.startDates.push(elm.readingFromDate);
          this.startDates = this.startDates.sort((a, b) => new Date(b).getTime() - new Date(a).getTime());
          
          this.endDates.push(elm.readingToDate);
          this.endDates = this.endDates.sort((a, b) => new Date(b).getTime() - new Date(a).getTime());
        });
      },
      error:(err)=>{
        this.isReportCrieteriaLoading = false;
        this.isAPIFailure = true;
        this.integrationErrorMsg = err.error?.errorDetails ? err.error.errorDetails?.message : err.errorDescription;        
      }
    })
    
    }

  onSearchClick(){
    this.isReportLoading = true;
    this.searchClicked = true;
    this.reportMgrSvc.getGenUnitSubBillAccountReport(this.selectedAccount.billAccountNumber,this.dtBegin, this.dtEnd, this.selectedOwner.ownerId).subscribe({
      next: (resp)=>{
        this.isReportLoading = false;
        this.reportData = resp;
      },
      error:(err)=>{
        this.isReportLoading = false;
        if(err && err?.error && err?.error?.code == 400 && err?.error?.errorDetails){
          this.reportData = [];
        }
      }
    })
  }

  exportReport() {
    let keyHeaders: string[] = [];
    let modifiedlist: any = [];
    let displayHeaders: string[] = [
      "Bill Account",
      "Subscriber Name",
      "Meter Number",
      "Generation Owner",
      "Unit Nick Name",
      "Date Reading From",
      "Date Reading To",
      "KWH to Subscriber",
      "Bill Saving Rate",
      "Monetory Savings",
      "Sub Fees Billed"
    ];

    keyHeaders = [
      "billAccountNumber",
      "subscriberName",
      "meterNo",
      "ownerName",
      "nickName",
      "readingFromDate",
      "readingToDate",
      "subscribeQuantity",
      "billSavingsRate",
      "billSavingsAmount",
      "subFeesBilled"
    ];

    this.reportData.forEach((row: any) => {
      let temp: any = {};

      temp["billAccountNumber"] = row.billAccountNumber;
      temp["subscriberName"] = row.subscriberName;
      temp["meterNo"] = row.meterNo;
      temp["ownerName"] = row.ownerName;
      temp["nickName"] = row.nickName;

      temp["readingFromDate"] = row.readingFromDate;
      temp["readingToDate"] = row.readingToDate;
      temp["subscribeQuantity"] = row.subscribeQuantity;

      temp["billSavingsRate"] = row.billSavingsRate;
      temp["billSavingsAmount"] = row.billSavingsAmount;

      temp["subFeesBilled"] = row.subFeesBilled;



      modifiedlist.push(temp);
    });
    this._export.exportJsonToExcelFile(
      modifiedlist,
      "generation unit subscriber bill account report",
      false,
      keyHeaders,
      displayHeaders
    );
  }
}
