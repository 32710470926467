<div class="gen_unit_sub_bill_account_report_container">
  <div class="gen_unit_sub_bill_account_report_title">
    Generation Unit-Subscriber Bill Account Report
  </div>

  <div class="text-center" *ngIf="!owners">
    <ameren-loading></ameren-loading>
    <p>Please wait..</p>
  </div>  

  <div class="row">
    <div class="col form-group">
      <label>Generation Owner Name<span class="text-danger">*</span></label>
      <select class="form-select" [(ngModel)]="selectedOwner" (change)="onOwnerChange()">
        <option *ngFor="let o of owners" [ngValue]="o">{{ o.name }}</option>
      </select>
    </div>

    <div class="col form-group">
      <label
        >Subscriber Bill Account Number<span class="text-danger">*</span></label
      >
      <select
        class="form-select" [disabled]="!subscribers"
        [(ngModel)]="selectedAccount"
        (change)="onBillAccountChange()"
      >
        <option *ngFor="let b of subscribers" [ngValue]="b">
          {{
            b.billAccountNumber +
            (b.subscriberName != '' ? '- Subscriber - '  +
              b.subscriberName : '' )             
          }}
        </option>
      </select>
    </div>
    
    <div class="col form-group">
      <label>Meter Number<span class="text-danger">*</span></label>
      <select
        class="form-select"
        [(ngModel)]="selectedMeter"
        (change)="onMeterChange()"
        [disabled]="!selectedAccount"
      >
        <option *ngFor="let m of meters" [ngValue]="m">
          {{ m.meterNo + '- ServicePoint- ' + m.servicePt }}
        </option>
      </select>
    </div>
  </div>

  <div class="row" *ngIf="subscribers && owners">
    <div class="col form-group">
      <label
        >Min Reading From Billing Month<span class="text-danger">*</span></label
      >
      <select
        class="form-select"
        [(ngModel)]="dtBegin"
        [disabled]="!startDates || !startDates.length"
      >
        <option *ngFor="let s of startDates" [ngValue]="s">
          {{ s | date : 'MM/dd/yyyy' }}
        </option>
      </select>
    </div>
    <div class="col form-group">
      <label
        >Max Reading To Billing Month<span class="text-danger">*</span></label
      >
      <select
        class="form-select"
        [(ngModel)]="dtEnd"
        [disabled]="!endDates || !endDates.length"
      >
        <option *ngFor="let e of endDates" [ngValue]="e">
          {{ e | date : 'MM/dd/yyyy' }}
        </option>
      </select>
    </div>
    <div class="col gen_unit_sub_bill_account_report_btn_container">
      <button
        class="btn btn-outline-success"
        [disabled]="
          !dtBegin ||
          !dtEnd ||
          !endDates.length ||
          !startDates.length ||
          !selectedAccount ||
          !selectedMeter
        "
        (click)="onSearchClick()"
      >
        Search
      </button>
      <button
        class="btn btn-outline-success m-2"
        [disabled]="!reportData || !reportData.length"
        (click)="exportReport()"
      >
        <img
          src="assets/icon/excelicon.svg"
          alt="CalendarImage"
          width="22"
          height="19"
        />
        Export
      </button>
    </div>
  </div>

  <table
    class="gen_unit_sub_bill_account_report_selections"
    aria-describedby="gen unit subsriber billaccount report"
    *ngIf="
      dtBegin && dtBegin && dtEnd && dtBegin && selectedAccount && selectedMeter
    "
  >
    <th class="sr-only"></th>
    <caption class="sr-only">
      Selected Report Creteria - Generation Unit Subscriber Bill Account Report
    </caption>
    <tbody>
      <tr>
        <td><label> Generation Owner Name: </label></td>
        <td>{{ selectedAccount?.ownerName }}</td>
      </tr>
      <tr>
        <td><label> Min Reading From Billing Date: </label></td>
        <td>{{ dtBegin | date : 'MM/dd/yyyy' }}</td>
        <td>
          <label> Max Reading To Billing Date: </label>
        </td>
        <td>
          {{ dtEnd | date : 'MM/dd/yyyy' }}
        </td>
      </tr>
    </tbody>
  </table>

  <div
    class="text-center"
    id="loading_report_criteria"
    *ngIf="isReportCrieteriaLoading"
  >
    <ameren-loading></ameren-loading>
    <p>Please wait..</p>
  </div>
  <div
  *ngIf="isAPIFailure"
  class="mt-3 alert text-center alert-danger"
  role="alert"
>
  {{ integrationErrorMsg }}
</div>

  <table
    class="table gen_unit_sub_billaccount_report_grid"
    *ngIf="searchClicked"
  >
    <caption class="sr-only">
      Generation Unit Subscriber Bill Account Report
    </caption>
    <thead>
      <tr>
        <th scope="col">Bill Account</th>
        <th scope="col">Subscriber Name</th>
        <th scope="col">Meter Number</th>
        <th scope="col">Generation Owner</th>
        <th scope="col">Unit Nick Name</th>
        <th scope="col">Date Reading From</th>
        <th scope="col">Date Reading To</th>
        <th scope="col">KWH to Subscriber</th>
        <th scope="col">Bill Savings Rate</th>
        <th scope="col">Monetary Savings</th>
        <th scope="col">Subscription Fees Billed</th>
        <th>Subscription Fee Paid</th>
        <th>Subscription Fee Date Paid</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngIf="!isReportLoading && (!reportData || !reportData.length)">
        <td colspan="9" class="text-center">No data</td>
      </tr>
      <tr *ngIf="isReportLoading">
        <td colspan="9" class="text-center">
          <p>Please wait..</p>
          <ameren-loading></ameren-loading>
        </td>
      </tr>
      <ng-container *ngIf="!isReportLoading">
        <tr *ngFor="let rep of reportData">
          <td>
            {{ rep.billAccountNumber }}
          </td>
          <td>
            {{ rep.subscriberName }}
          </td>
          <td>
            {{ rep.meterNo }}
          </td>
          <td>
            {{ rep.ownerName }}
          </td>
          <td>
            {{ rep.nickName }}
          </td>
          <td>
            {{ rep.readingFromDate | date : 'MM/dd/yyyy' }}
          </td>
          <td>{{ rep.readingToDate | date : 'MM/dd/yyyy' }}</td>
          <td>
            {{ rep.subscribeQuantity }}
          </td>
          <td>${{ rep.billSavingsRate | number : '1.8' }}</td>
          <td>
            ${{ rep.billSavingsAmount | number: '1.2'}}
          </td>        
           <td>
            ${{ rep.subFeesBilled | number : '1.2' }}
          </td>
          <td>
            ${{rep.subFeesPaid | number: '1.2'}}
          </td>
          <td>
            <span *ngIf="rep.lastPaymentDate != '0001-01-01'">{{rep.lastPaymentDate | date:'MM/dd/yyyy'}}</span>
            <span *ngIf="rep.lastPaymentDate == '0001-01-01'">Not Paid</span>
          </td>
        </tr>
      </ng-container>
    </tbody>
  </table>
</div>
