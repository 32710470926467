<div class="subscriber-container">
  <div class="subscriber-header">Subscribers</div>
  <div class="col-md-12 subsriber-header-content">
    <em>
      Disclaimer: COGF Participating Customers will be referred to throughout
      this portal as Subscribers.
    </em>
  </div>
  <div class="col-md-12" id="subscriber-search-container-main">
    <div class="row subscriber_search_container">
      <div class="col-md-1"></div>
      <div
        (click)="onToggleActiveInactive()"
        class="col-md-2 form-check pl-2 gen_unit_subscriber_active_switch"
      >
        <input
          class="form-check-input"
          type="checkbox"
          value=""
          id="showInactiveSubscribers"
          [checked]="showInactiveSubscribers"
        />
        <label class="form-check-label" for="showInactiveSubscribers">
          Show Inactive Subscribers
        </label>
      </div>
      <div class="subscriber_search_container_search_box col-md-4">
        <div class="d-flex flex-row gen_owner__search">
          <div class="input-group search_button searchuser mb-3 ms-auto">
            <input
              type="text"
              class="form-control mt-0 searchinput"
              placeholder="Search for Subscriber..."
              aria-label="searchAmerenUser"
              [(ngModel)]="searchString"
              (ngModelChange)="searchFilter()"
              aria-describedby="searchAmerenUser"
            />
            <span
              class="input-group-text"
              id="searchAmerenUser"
              (click)="searchFilter()"
              ><em class="fa fa-search"></em
            ></span>
          </div>
        </div>
      </div>
      <div
        class="subscriber_search_container_btn_container text-right col-md-5"
      >
        <button
          type="button"
          class="btn btn-success gen_owner_export"
          (click)="exportSubscribers()"
        >
          <img
            src="assets/icon/excelicon.svg"
            alt="CalendarImage"
            width="22"
            height="19"
          />
          Export
        </button>

        <button
          type="button"
          class="btn ameren-primary-red-btn"
          data-bs-toggle="modal"
          data-bs-target="#removeSubscribersModal"
          [disabled]="
            !selectedSubscribersToRemove || !selectedSubscribersToRemove.length
          "
          *ngIf="functionalityAccess?.allowEnableDisableSubscribers"
        >
          Remove Subscriber
        </button>
        <a
          [routerLink]="['/subscribers/add']"
          class="btn btn-success"
          *ngIf="functionalityAccess?.allowCreateSubscriber"
          >Add Subscriber</a
        >
      </div>
    </div>

    <div class="mt-3">
      <mat-paginator
        *ngIf="subscribers && subscribers.length > 9"
        #paginator
        [pageSize]="pageSize"
        [pageSizeOptions]="[10]"
        [showFirstLastButtons]="true"
        [length]="totalSize"
        [pageIndex]="currentPage"
        (page)="handlePage($event)"
      >
      </mat-paginator>
    </div>

    <table class="table subscriber_grid">
      <caption class="sr-only">
        Subscribers List
      </caption>
      <thead>
        <tr>
          <th
            scope="col"
            *ngIf="functionalityAccess?.allowEnableDisableSubscribers"
          >
            Select
          </th>
          <th scope="col">
            <span
              class="table-head"
              [appSort]="pagedSubscribers"
              data-order="asc"
              data-name="billAccountNumber"
              >Subscriber Bill Account Number <em class="fa-solid fa-sort"></em
            ></span>
          </th>
          <th scope="col">
            <span
              class="table-head"
              [appSort]="pagedSubscribers"
              data-order="asc"
              data-name="subscriberName"
              >Subscriber Name <em class="fa-solid fa-sort"></em
            ></span>
          </th>
          <th scope="col">
            <span
              class="table-head"
              [appSort]="pagedSubscribers"
              data-order="asc"
              data-name="meterNo"
              >Meter Number <em class="fa-solid fa-sort"></em
            ></span>
          </th>
          <th scope="col">
            <span
              class="table-head"
              [appSort]="pagedSubscribers"
              data-order="asc"
              data-name="usageQuantity"
              >Subscribed KW <em class="fa-solid fa-sort"></em
            ></span>
          </th>
          <th scope="col">
            <span
              class="table-head"
              [appSort]="pagedSubscribers"
              data-order="asc"
              data-name="effectiveDate"
              data-type="date"
              >Active Date <em class="fa-solid fa-sort"></em
            ></span>
          </th>
          <th scope="col">
            <span
              class="table-head"
              [appSort]="pagedSubscribers"
              data-order="asc"
              data-name="subscriberAddress"
              >Address <em class="fa-solid fa-sort"></em
            ></span>
          </th>
          <th scope="col">
            <span
              class="table-head"
              [appSort]="pagedSubscribers"
              data-order="asc"
              data-name="terminationDate"
              data-type="date"
              >Termination Date <em class="fa-solid fa-sort"></em
            ></span>
          </th>
          <th scope="col" *ngIf="!isCRGP">
            <span
              class="table-head"
              [appSort]="pagedSubscribers"
              data-order="asc"
              data-name="annualMonth"
              >Annual Period <em class="fa-solid fa-sort"></em
            ></span>
          </th>
          <th scope="col">
            <span
              class="table-head"
              [appSort]="pagedSubscribers"
              data-order="asc"
              data-name="statusDescription"
              >Status <em class="fa-solid fa-sort"></em
            ></span>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngIf="!isSubscriberListLoading && !subscribers.length">
          <td colspan="9" class="text-center">No data</td>
        </tr>
        <tr *ngIf="isSubscriberListLoading">
          <td colspan="9" class="text-center">
            <p>
              Data Loading: Thanks for your patience as we download your
              requested data.
            </p>
            <ameren-loading></ameren-loading>
          </td>
        </tr>
        <ng-container *ngIf="!isSubscriberListLoading">
          <tr *ngFor="let sub of pagedSubscribers">
            <td *ngIf="functionalityAccess?.allowEnableDisableSubscribers">
              <input
                type="checkbox"
                [checked]="sub.selected"
                (click)="selectSubscriber(sub)"
              />
            </td>
            <td>
              {{ sub.billAccountNumber }}
            </td>
            <td>
              <a
                *ngIf="
                  sub.statusCode === '02' &&
                  functionalityAccess.allowEditSubscriber
                "
                [routerLink]="['/subscribers/edit']"
                [queryParams]="{
                  oId: sub.ownerId,
                  uId: sub.unitId,
                  ba: sub.billAccountNumber
                }"
              >
                {{ sub.subscriberName }}</a
              >
              <span
                *ngIf="
                  sub.statusCode != '02' ||
                  !functionalityAccess.allowEditSubscriber
                "
                >{{ sub.subscriberName }}</span
              >
            </td>
            <td>
              {{ sub.meterNo }}
            </td>
            <td>
              {{ sub.usageQuantity }}
            </td>
            <td>
              {{ sub.effectiveDate }}
            </td>
            <td>
              {{ sub.subscriberAddress }}
            </td>
            <td>
              <span *ngIf="showInactiveSubscribers || sub.showTerminationDate">
                {{ sub.terminationDate }}</span
              >
            </td>
            <td *ngIf="!isCRGP">{{ sub.annualMonth }}</td>
            <td [class.text-success]="sub.statusDescription == 'Active'">
              {{ sub.statusDescription }}
            </td>
          </tr>
        </ng-container>
      </tbody>
    </table>
  </div>

  <div
    class="modal fade remove-subs-modal"
    id="removeSubscribersModal"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header remove_gen_unit_modal_header border-0">
          <h5
            class="modal-title text-success remove_gen_unit_modal_title"
            id="removeSubscribersModalLabel"
          >
            {{
              !isRemoveSuccess && !isRemoveError
                ? 'Remove Subscriber(s)'
                : 'Remove Status'
            }}
          </h5>
          <button
            type="button"
            (click)="closeRemoveModal()"
            *ngIf="!isRemoveLoading"
            class="btn-close gen_unit_modal_close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="remove_gen_unit_modal_content">
            <div *ngIf="!isRemoveLoading && !isRemoveError && !isRemoveSuccess">
              <div class="form-group col-md-6">
                <label>Termination Date:*</label>
                <input
                  type="date"
                  class="form-control remove_subscriber_term_date" [min]="minDate"
                  [(ngModel)]="removeEffectiveDt"
                />
              </div>
              <table
                class="table subscriber_grid"
                id="remove_subscriber_modal_grid"
              >
                <caption class="sr-only">
                  Selected Subscribers to be removed
                </caption>
                <thead>
                  <tr>
                    <th scope="col">Generation Owner</th>
                    <th scope="col">Generation Unit Nickname</th>
                    <th scope="col">Subscriber Name</th>
                    <th scope="col">Subscriber Bill Account Number</th>
                    <th scope="col">Meter Number Date</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let s of selectedSubscribersToRemove">
                    <td>{{ s.ownerName }}</td>
                    <td>{{ s.unitName }}</td>
                    <td>{{ s.subscriberName }}</td>
                    <td>{{ s.billAccountNumber }}</td>
                    <td>{{ s.meterNo }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div
              *ngIf="isRemoveLoading"
              class="text-center justify-content-center"
            >
              <ameren-loading></ameren-loading>
              <p>Please wait..</p>
            </div>
            <div
              class="text-danger text-center justify-content-center"
              *ngIf="isRemoveError"
            >
              <img src="../../../assets/images/failure.svg" alt="success" />
              <p class="mt-2">{{ removeErrorMsg }}</p>
            </div>
            <div
              class="text-success text-center justify-content-center"
              *ngIf="isRemoveSuccess"
            >
              <img src="../../../assets/images/success.svg" alt="success" />
              <p class="mt-2">{{removeSuccessMsg}}</p>
            </div>
          </div>
        </div>
        <div
          class="text-center justify-content-center mb-3"
          *ngIf="!isRemoveSuccess && !isRemoveError && !isRemoveLoading"
        >
          <button
            type="button"
            (click)="closeRemoveModal()"
            class="btn btn-outline-secondary ps-4 px-4"
            data-bs-dismiss="modal"
          >
            Cancel
          </button>

          <button
            [disabled]="!removeEffectiveDt"
            type="button"
            (click)="removeSubscribers()"
            class="btn btn-success ms-2 ps-4 px-4"
          >
            Remove
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
