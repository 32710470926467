<div class="manage-subscription-fee-container">
  <div class="manage-subscription-fee-header">Manage Subscription Fees</div>

  <ng-container
    *ngIf="
      !isRateHistoryLoading && !isGenerationUnitLoading;
      else showLoadingIndicator
    "
  >
    <ng-container
      *ngIf="
        !isGenerationUnitAPIFailure;
        else showAPIFailureMessage
      "
    >
      <!-- Form goes here -->
      <div class="card m-md-5 m-sm-0">
        <div class="card-body">
          <div class="row">
            <div class="col-md-3 col-sm-12">
              <label class="form-label fw-bold" for="owner">Owner: </label>
            </div>
            <div class="col-md-3 col-sm-12">
              <span class="form-label fw-normal" id="owner"
                >{{ generationUnitDetails.ownerName }}
              </span>
            </div>
          </div>

          <div class="row">
            <div class="col-md-3">
              <label class="form-label fw-bold" for="nickName"
                >Nickname:
              </label>
            </div>
            <div class="col-md-3">
              <span class="form-label fw-normal" id="owner"
                >{{ generationUnitDetails.nickName }}
              </span>
            </div>
          </div>

          <div class="row">
            <div class="col-md-3">
              <label class="form-label fw-bold" for="unitId">Unit Id: </label>
            </div>
            <div class="col-md-3">
              <span class="form-label fw-normal" id="unitId"
                >{{ generationUnitDetails.unitId }}
              </span>
            </div>
          </div>

          <div class="row">
            <div class="col-md-3">
              <label class="form-label fw-bold" for="bankingStatus"
                >Banking Status:
              </label>
            </div>
            <div class="col-md-3">
              <span class="form-label fw-normal" id="bankingStatus"
                >{{ bankingStatus }}
              </span>
            </div>
          </div>

          <form [formGroup]="subscriptionFeeForm" class="needs-validation mt-3">
            <div class="row">
              <div class="col-md-5">
                <div class="residential-subscription-fee m-4">
                  <div class="text-center fw-bold">Residential</div>
                  <div class="row">
                    <div class="col">
                      <div class="form-group">
                        <label class="form-label fw-bold" for="resBillname"
                          >Bill Name</label
                        >
                        <input
                          class="form-control"
                          type="text"
                          id="resBillName"
                          class="form-control"
                          formControlName="resBillName"
                        />

                        <div
                          class="text-field-error mt-3 ameren-font-sm"
                          *ngIf="
                            subscriptionFeeForm?.get('resBillName')?.dirty &&
                            subscriptionFeeForm?.get('resBillName')?.invalid
                          "
                        >
                          <p
                            *ngIf="subscriptionFeeForm?.get('resBillName')?.errors?.['pattern']"
                          >
                            Bill Name is Invalid.
                          </p>
                        </div>
                      </div>

                      <div class="form-group">
                        <label class="form-label fw-bold" for="resSubPercentage"
                          >Default Rate</label
                        >
                        <div class="input-group">
                          <input
                            class="form-control"
                            type="number"
                            id="resSubPercentage"
                            class="form-control"
                            formControlName="resSubPercentage"
                          />
                          <div class="input-group-append">
                            <span class="input-group-text mt-1">%</span>
                          </div>
                        </div>

                        <div
                          class="text-field-error mt-3 ameren-font-sm"
                          *ngIf="
                            subscriptionFeeForm?.get('resSubPercentage')
                              ?.dirty &&
                            subscriptionFeeForm?.get('resSubPercentage')
                              ?.invalid
                          "
                        >
                          <p
                            *ngIf="subscriptionFeeForm?.get('resSubPercentage')?.errors?.['pattern']"
                          >
                            Default Rate is Invalid.
                          </p>
                        </div>
                      </div>

                      <div class="form-group" *ngIf="showEffectiveDate">
                        <label class="form-label fw-bold" for="resEffectiveDate"
                          >Effective Date</label
                        >
                        <input
                          class="form-control"
                          type="date"
                          id="resEffectiveDate"
                          class="form-control"
                          formControlName="resEffectiveDate"
                        />

                        <div
                          class="text-field-error mt-3 ameren-font-sm"
                          *ngIf="
                            subscriptionFeeForm?.get('resEffectiveDate')
                              ?.dirty ||
                            subscriptionFeeForm?.get('resEffectiveDate')
                              ?.invalid
                          "
                        >
                          <p
                            *ngIf="subscriptionFeeForm?.get('resEffectiveDate')?.errors?.['pattern']"
                          >
                            Effective Date is Invalid.
                          </p>
                          <p
                            *ngIf="subscriptionFeeForm?.get('resEffectiveDate')?.errors?.['InvalidDate']"
                          >
                            Date must be current or future.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-5">
                <div class="non-residential-subscription-fee m-4">
                  <div class="text-center fw-bold">Non-Residential</div>
                  <div class="row">
                    <div class="col">
                      <div class="form-group">
                        <label class="form-label fw-bold" for="nonResBillName"
                          >Bill Name</label
                        >
                        <input
                          class="form-control"
                          type="text"
                          id="nonResBillName"
                          class="form-control"
                          formControlName="nonResBillName"
                        />

                        <div
                          class="text-field-error mt-3 ameren-font-sm"
                          *ngIf="
                            subscriptionFeeForm?.get('nonResBillName')?.dirty &&
                            subscriptionFeeForm?.get('nonResBillName')?.invalid
                          "
                        >
                          <p
                            *ngIf="subscriptionFeeForm?.get('nonResBillName')?.errors?.['pattern']"
                          >
                            Bill Name is Invalid.
                          </p>
                        </div>
                      </div>

                      <div class="form-group">
                        <label
                          class="form-label fw-bold"
                          for="nonResSubPercentage"
                          >Default Rate</label
                        >
                        <div class="input-group">
                          <input
                            class="form-control"
                            type="number"
                            id="nonResSubPercentage"
                            class="form-control"
                            formControlName="nonResSubPercentage"
                          />
                          <div class="input-group-append">
                            <span class="input-group-text mt-1">%</span>
                          </div>
                        </div>

                        <div
                          class="text-field-error mt-3 ameren-font-sm"
                          *ngIf="
                            subscriptionFeeForm?.get('nonResSubPercentage')
                              ?.dirty &&
                            subscriptionFeeForm?.get('nonResSubPercentage')
                              ?.invalid
                          "
                        >
                          <p
                            *ngIf="subscriptionFeeForm?.get('nonResSubPercentage')?.errors?.['pattern']"
                          >
                            Default Rate is Invalid.
                          </p>
                        </div>
                      </div>

                      <div class="form-group" *ngIf="showEffectiveDate">
                        <label
                          class="form-label fw-bold"
                          for="nonResEffectiveDate"
                          >Effective Date</label
                        >
                        <input
                          class="form-control"
                          type="date"
                          id="nonResEffectiveDate"
                          class="form-control"
                          formControlName="nonResEffectiveDate"
                        />

                        <div
                          class="text-field-error mt-3 ameren-font-sm"
                          *ngIf="
                            subscriptionFeeForm?.get('nonResEffectiveDate')
                              ?.dirty ||
                            subscriptionFeeForm?.get('nonResEffectiveDate')
                              ?.invalid
                          "
                        >
                          <p
                            *ngIf="subscriptionFeeForm?.get('nonResEffectiveDate')?.errors?.['pattern']"
                          >
                            Effective Date is Invalid.
                          </p>
                          <p
                            *ngIf="subscriptionFeeForm?.get('nonResEffectiveDate')?.errors?.['InvalidDate']"
                          >
                            Date must be current or future.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group">
              <div class="form-check">
                <input
                  type="checkbox"
                  class="form-check-input"
                  id="terminateSubsFee"
                  formControlName="termindateBillingSubscriptionFee"
                />
                <label class="form-check-label"
                  >Terminate Billing Subscription Fees</label
                >
                <div
                  class="small alert alert-danger"
                  role="alert"
                  *ngIf="
                    this.subscriptionFeeForm.get(
                      'termindateBillingSubscriptionFee'
                    )?.value
                  "
                >
                  This will terminate all subscription revenue for this
                  facility. Please proceed further if you are sure about this
                  action.
                </div>
              </div>
            </div>

            <div class="form-group">
              <div class="d-grid gap-2 d-md-flex mt-5 justify-content-center">
                <button
                  class="ameren-secondary-green-btn"
                  type="submit"
                  (click)="cancelSubscriptionFee()"
                >
                  Cancel
                </button>
                <button
                  [disabled]="subscriptionFeeForm.invalid"
                  class="btn btn-success ameren-primary-green-btn"
                  type="submit"
                  (click)="submitSubscriptionFee()"
                >
                  Save
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>

      <div class="container">
        <mat-paginator
          *ngIf="rates && rates.length > 9"
          #paginator
          [pageSize]="pageSize"
          [pageSizeOptions]="[10]"
          [showFirstLastButtons]="true"
          [length]="totalSize"
          [pageIndex]="currentPage"
          (page)="handlePage($event)"
        >
        </mat-paginator>

        <table
          class="table table-responsive mt-5 subscription-fee-records"
          aria-describedby="subscription-fee"
        >
          <thead>
            <tr>
              <th scope="col">Subscription Rate Name</th>
              <th scope="col">Res/Com</th>
              <th scope="col">Effective Date</th>
              <th scope="col">Termination Date</th>
              <th scope="col">Subscription Percentage</th>
            </tr>
          </thead>
          <tbody>
            <tr
              *ngIf="
                !isRateHistoryAPIFailure &&
                !isRateHistoryLoading &&
                rebateHistory.length === 0
              "
            >
              <td colspan="6" class="text-center">No data</td>
            </tr>
            <tr *ngIf="isRateHistoryAPIFailure && !isRateHistoryLoading">
              <td colspan="6" class="text-center text-danger">
                Something went wrong. Please try again.
              </td>
            </tr>
            <tr *ngIf="isRateHistoryLoading">
              <td colspan="6" class="text-center">
                <ameren-loading></ameren-loading>
                <p>Please wait..</p>
              </td>
            </tr>
            <tr *ngFor="let rebate of rebateHistory">
              <td>
                {{ rebate.billName }}
              </td>
              <td>
                {{ rebate.tariffType }}
              </td>
              <td>
                {{ rebate.effectiveDate }}
              </td>

              <td>
                {{ rebate.terminationDate }}
              </td>
              <td>
                {{ rebate.subPercentage }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </ng-container>
  </ng-container>

  <ng-template #showAPIFailureMessage>
    <div class="mt-3 alert alert-danger" role="alert">
      Sorry, Something went wrong. Please try again.
    </div>
  </ng-template>

  <ng-template #showLoadingIndicator>
    <ameren-loading class="d-flex justify-content-center"
      >Loading...</ameren-loading
    >
  </ng-template>
</div>

<div
  id="submitSubscriptionModal"
  class="modal fade"
  aria-hidden="true"
  aria-labelledby="amerenModal"
  tabindex="-1"
>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header" *ngIf="!isSubmitScriptionLoading">
        <div
          class="ameren__modal__success-title"
          *ngIf="!isSubmitScriptionLoading && !isSubmitScriptionFailed"
        >
          Successfully Submitted!
        </div>
        <div
          class="ameren__modal__failure-title"
          *ngIf="!isSubmitScriptionLoading && isSubmitScriptionFailed"
        >
          Submission Error!
        </div>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body">
        <div *ngIf="isSubmitScriptionLoading" class="text-center">
          <ameren-loading
            id="loadingIndicator"
            class="d-flex p-10 justify-content-center"
          ></ameren-loading>
          <span>Please wait...</span>
        </div>
        <div class="ameren__modal-image">
          <img
            *ngIf="!isSubmitScriptionFailed && !isSubmitScriptionLoading"
            src="assets/images/success.svg"
            alt="Success Image"
          />
          <img
            *ngIf="isSubmitScriptionFailed && !isSubmitScriptionLoading"
            src="assets/images/failure.svg"
            alt="Failure Image"
          />
        </div>
        <div class="ameren__modal-footer" *ngIf="!isSubmitScriptionLoading">
          <div
            class="ameren__modal-message text-center justify-content-center"
            *ngIf="!isSubmitScriptionLoading && !isSubmitScriptionFailed"
          >
            Subscription Fee has been submitted successfully.
          </div>

          <div
            class="ameren__modal-message text-center justify-content-center"
            *ngIf="!isSubmitScriptionLoading && isSubmitScriptionFailed"
          >
            Subscription Fee has not been submitted successfully. Please try
            again to submit your request.
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade"
  id="subscriptionFeeCancelModal"
  data-bs-backdrop="static"
  data-bs-keyboard="false"
  tabindex="-1"
  aria-labelledby="staticBackdropLabel"
  aria-hidden="true"
>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title text-black" id="editGenUnitCancelModalLabel">
          Confirm Cancellation
        </h5>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body">
        <p>Are you sure you want to cancel?</p>
      </div>
      <div class="modal-footer">
        <a [routerLink]="['/subscription-detail']"
          [queryParams]="{
            unitId: generationUnitDetails?.unitId,
            acct: generationUnitDetails?.billAccountNumber,
            ownerId: generationUnitDetails?.owner
          }"
          class="btn btn-success"
          data-bs-dismiss="modal"
          >Yes</a
        >

        <button
          type="button"
          data-bs-dismiss="modal"
          class="btn btn-danger"
          data-bs-dismiss="modal"
        >
          No
        </button>
      </div>
    </div>
  </div>
</div>
