import { AuthService } from './../../../shared/services/auth.service';
import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { SubscriberManagerServiceService } from '../services/subscriber-manager-service.service';
import { SubscriberModel } from '../models/subscriber.model';
import { Subject, delay, takeUntil, tap } from 'rxjs';
import { GenerationUnitManagerService } from '../../generation-unit/services/generation-unit-manager.service';

declare let window: any;

@Component({
  selector: 'app-edit-subscriber',
  templateUrl: './edit-subscriber.component.html',
  styleUrls: ['./edit-subscriber.component.scss'],
})
export class EditSubscriberComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  ownerId: any;
  unitId: any;
  billAccountNumber: any;
  editSubscriberForm: FormGroup;
  subscriptionDetails: SubscriberModel;
  enableSubscriptionOverrideRateField: boolean = false;
  enableSubscriptionOverrideDateField: boolean = false;
  enableEffectiveDate: boolean = false;
  overrideSubscription: boolean;
  CSSDateAPIFailure: boolean;
  CSSDateLoading: boolean;
  CSSDate: any;
  minDate: any;
  updateSubscriberCancelModal: any;
  updateSubscriberModal: any;

  viewLoaded: Subject<null>;
  destroyed$: Subject<boolean>;

  subscriberAPIFailure: boolean;
  subscriberLoading: boolean;

  updateSubscriberLoading: boolean;
  updateSubscriberAPFailure: boolean;

  errorMsg: string;
  isSaveComplete: boolean;
  updateSuccessMsg: string;

  constructor(
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private subscriberManager: SubscriberManagerServiceService,
    private generationUnitManager: GenerationUnitManagerService,
    private authService: AuthService
  ) {
    this.viewLoaded = new Subject();
    this.destroyed$ = new Subject();

    this.route.queryParams.subscribe((data) => {
      this.ownerId = data['oId'];
      this.unitId = data['uId'];
      this.billAccountNumber = data['ba'];
    });
  }

  ngOnInit(): void {
    this.initEditSubscriberForm();
    this.getSubscribersByBillAccount();
    this.getCSSDate();

    this.updateSubscriberCancelModal = new window.bootstrap.Modal(
      document.getElementById('updateSubscriberCancelModal'),
      {
        backdrop: true,
        keyboard: true,
      }
    );

    this.updateSubscriberModal = new window.bootstrap.Modal(
      document.getElementById('updateSubscriberModal'),
      {
        backdrop: true,
        keyboard: true,
      }
    );

    this.viewLoaded
      .pipe(
        delay(0),
        tap(() => {
          this.listenForChanges();
        }),
        takeUntil(this.destroyed$)
      )
      .subscribe();
  }

  listenForChanges() {
    this.editSubscriberForm
      .get('overrideSubscription')
      ?.valueChanges.pipe(takeUntil(this.destroyed$))
      .subscribe((value) => {
        (value && value == true) || this.subscriptionDetails.subscriberRate > 0 ? this.enableSubscriptionOverrideRateField = true
        : this.enableSubscriptionOverrideRateField = false;
    });

    this.editSubscriberForm
      .get('subscriberRate')
      ?.valueChanges.pipe(takeUntil(this.destroyed$))
      .subscribe((value) => {
        this.subscriptionDetails.subscriberRate != value
          ? (this.enableSubscriptionOverrideDateField = true)
          : (this.enableSubscriptionOverrideDateField = false);
    });

    this.editSubscriberForm?.get('usageQuantity')
    ?.valueChanges.pipe(takeUntil(this.destroyed$))
    .subscribe((value) => {
      this.subscriptionDetails.usageQuantity != value ? (this.enableEffectiveDate = true) : (this.enableEffectiveDate = false);
    });
    
    this.editSubscriberForm?.get('savingsReleaseFlag')
    ?.valueChanges.pipe(takeUntil(this.destroyed$))
    .subscribe((value) => {
      this.subscriptionDetails.savingsReleaseFlag != value ? (this.enableEffectiveDate = true) : (this.enableEffectiveDate = false);
    }); 
  }

  initEditSubscriberForm() {
    this.editSubscriberForm = this.fb.group({
      unitId: new FormControl(this.unitId, [Validators.required]),
      owner: new FormControl(this.ownerId),
      billAccountNumber: new FormControl(null, [
        Validators.required,
        Validators.minLength(10),
        Validators.maxLength(10),
        Validators.pattern(/^[0-9]\d*$/),
      ]),
      premiseNo: new FormControl(),
      servicePt: new FormControl(),
      meterNo: new FormControl(null, [
        Validators.required,
        Validators.pattern(/^[0-9]\d*$/),
      ]),
      effectiveDate: new FormControl(),
      timeStart: new FormControl('12.00.00'),
      usageQuantity: new FormControl('0.000', [
        Validators.required,
        Validators.pattern(/^\d*\.?.*/),
      ]),
      annualMonth: new FormControl(),
      annualMonthReq: new FormControl(),
      subscriberName: new FormControl(null, [Validators.required]),
      transferBillAccountNumber: new FormControl(),
      subscriberStatReason: new FormControl('A'),
      savingsReleaseFlag: new FormControl(false),
      subscriberRate: new FormControl(null, [Validators.pattern(/^[0-9]\d*$/)]),
      subscriberRateEffectiveDate: new FormControl(),
      customerConsent: new FormControl(false, [Validators.requiredTrue]),
      overrideSubscription: new FormControl(false),
    });
  }

  getSubscribersByBillAccount() {
    this.subscriberAPIFailure = false;
    this.subscriberLoading = true;

    this.subscriberManager
      .getSubscriberByBillAccount(
        this.ownerId,
        this.unitId,
        this.billAccountNumber
      )
      .subscribe({
        next: (data: any) => {
          this.subscriberAPIFailure = false;
          this.subscriberLoading = false;
          this.subscriptionDetails = data;
          this.editSubscriberForm
            .get('subscriberRate')
            ?.setValue(this.subscriptionDetails.subscriberRate);

          if (this.subscriptionDetails.subscriberRate > 0){
            this.editSubscriberForm
            .get('overrideSubscription')
            ?.setValue(true);
          }

          this.editSubscriberForm
            .get('usageQuantity')
            ?.setValue(this.subscriptionDetails.usageQuantity);

          this.editSubscriberForm
            .get('savingsReleaseFlag')
            ?.setValue(this.subscriptionDetails.savingsReleaseFlag);
        },
        error: (error: any) => {
          console.log('error', error);
          this.subscriberAPIFailure = true;
          this.subscriberLoading = false;
        },
      });
  }

  ngAfterViewInit(): void {
    this.viewLoaded.next(null);
  }

  updateSubscriber() {
    let body = {
      unitId: this.subscriptionDetails.unitId,
      owner: this.subscriptionDetails.ownerId,
      billAccountNumber: this.subscriptionDetails.billAccountNumber,
      premiseNo: this.subscriptionDetails.premiseNo,
      servicePt: this.subscriptionDetails.servicePt,
      meterNo: this.subscriptionDetails.meterNo,
      effectiveDate: this.enableEffectiveDate ? this.editSubscriberForm.get('effectiveDate')?.value : this.subscriptionDetails.effectiveDate,
      timeStart: '12.00.00',
      usageQuantity: this.editSubscriberForm.get('usageQuantity')?.value,
      annualMonth: this.subscriptionDetails.annualMonth,
      annualMonthReq: this.subscriptionDetails.annualMonthReq,
      subscriberName: this.subscriptionDetails.subscriberName,
      transferBillAccountNumber: '',
      subscriberStatReason: 'A',
      savingsReleaseFlag: this.editSubscriberForm.get('savingsReleaseFlag')
        ?.value
        ? true
        : false,
      subscriberRate: this.enableSubscriptionOverrideDateField
        ? Number(this.editSubscriberForm.get('subscriberRate')?.value)
        : this.subscriptionDetails.subscriberRate,
      subscriberRateEffectiveDate: this.enableSubscriptionOverrideDateField
        ? this.editSubscriberForm.get('subscriberRateEffectiveDate')?.value
        : this.subscriptionDetails.subscriberRateEffectiveDate,
    };    

    if(this.subscriptionDetails.usageQuantity == body.usageQuantity){
      body.effectiveDate = '0001-01-01';
    }

    this.updateSubscriberModal.show();
    this.updateSubscriberLoading = true;
    this.updateSubscriberAPFailure = false;
    this.subscriberManager.updateSubscriber(body).subscribe({
      next: (data: any) => {
        this.updateSubscriberLoading = false;
        this.updateSubscriberAPFailure = false;
        this.updateSuccessMsg = 'Successfully Submitted!';
        this.isSaveComplete = true;
      },
      error: (error: any) => {
        if (error && error.status == 504) {
          this.updateSubscriberAPFailure = false;
          this.updateSuccessMsg = 'Your change was successful. The system is now processing your request. If you check back later and do not see your changes, please contact RenewablesIllinois@ameren.com';
          this.isSaveComplete = true;
        } else {
          if (!error.error.errorDetails.message.startsWith("Internal Error"))
            this.errorMsg = error.error.errorDetails.message;
          this.updateSubscriberAPFailure = true;
          this.isSaveComplete = false;
        }
        this.updateSubscriberLoading = false;
      },
    });
  }

  getCSSDate() {
    this.CSSDateAPIFailure = false;
    this.CSSDateLoading = true;
    this.generationUnitManager.getCSSDate().subscribe({
      next: (data: any) => {
        this.CSSDateAPIFailure = false;
        this.CSSDateLoading = false;
        this.CSSDate = data.substring(0, 10);        
        this.minDate = this.CSSDate;
        let portalAccess = this.authService.getPortalFunctioanalityAccesses();
        if (portalAccess.isAmerenAdmin) {
          this.minDate = "0001-01-01";
        }
        this.editSubscriberForm
          .get("subscriberRateEffectiveDate")
          ?.setValue(this.CSSDate);
        this.editSubscriberForm.get("effectiveDate")?.setValue(this.CSSDate);
      },
      error: (error: any) => {
        console.log('error', error);
        this.CSSDateAPIFailure = true;
        this.CSSDateLoading = false;
      },
    });
  }

  cancelUpdateSubscriber() {
    this.updateSubscriberCancelModal.show();
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.unsubscribe();
  }
}
