import { ReportsManagerService } from './../services/reports-manager.service';
import { of } from "rxjs";
import { catchError } from "rxjs";
import { combineLatest, Observable, takeUntil, Subject } from "rxjs";
import { ExportUtility } from "./../../../shared/utilities/utility.export";
import { GenerationOwnerManagerService } from "./../../generation-owner/services/generation-owner-manager.service";
import { Component, OnInit, OnDestroy } from "@angular/core";

@Component({
  selector: "app-ipa-gen-unit-sub-contract-rpt",
  templateUrl: "./ipa-gen-unit-sub-contract-rpt.component.html",
  styleUrls: ["./ipa-gen-unit-sub-contract-rpt.component.scss"],
})
export class IpaGenUnitSubContractRptComponent implements OnInit, OnDestroy {
  constructor(
    private genOwnerMgr: GenerationOwnerManagerService,
    private _export: ExportUtility,
    private reportMgr: ReportsManagerService
  ) {}

  genOwners: any[];
  selectedOwner: any;
  cssDate: string;
  minDate: string;
  maxDate: string;
  reportData: any[] = [];
  units: any[] = [];
  selectedUnit: any;
  isApiLoading: boolean;
  isReportLoading: boolean;
  searchClicked: boolean;
  dtBegin: string;
  dtEnd: string;

  unsubscribe$ = new Subject();

  ngOnInit(): void {
    this.getOwners();
  }

  getOwners() {
    this.genOwnerMgr.getAllOwners().subscribe({
      next: (resp) => {
        this.genOwners = resp;
        this.getUnitsByOwner();
      },
    });
  }

  onOwnerChange() {
    this.selectedUnit = undefined;
    this.getUnitsByOwner();
  }
  getUnitsByOwner() {
    let reqs: Observable<any>[] = [];
let counter = 1;
    this.genOwners.forEach((owner: any) => {
      reqs.push(
        this.genOwnerMgr
          .getGenerationUnitsByOwnerId(owner.ownerId)
          .pipe(catchError(() => of(null)))
      );
    });
    combineLatest(reqs)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((resp) => {
        resp.forEach((unit) => {
          if (unit && unit.length) {
            this.units.push(...unit);
          }
        });
      
      });
  }
  search() {
    this.searchClicked = true;
    this.isReportLoading = true;

    let reportReqs: Observable<any>[] = [];
    this.units.forEach((unit: any) => {
      reportReqs.push(
        this.reportMgr
          .getGenUnitSubscriberContractReport(
            this.dtBegin,
            this.dtEnd,
            unit.owner,
            unit.unitId
          )
          .pipe(catchError(() => of(null)))
      );
    });


    
    combineLatest(reportReqs)
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe((resp) => {
      resp.forEach((rpt) => {
        if (rpt && rpt.length) {
          this.reportData.push(...rpt);
        }
      });
    
      this.isReportLoading = false;
    });
  }

  exportReport() {
    let keyHeaders: string[] = [];
    let modifiedlist: any = [];
    let displayHeaders: string[] = [
      "Generation Owner Name",
      "Generation ID",
      "Date Energized",
      "Subscriber Name",     
      "Subscriber Identification Number",
      "Subscribed KW",
      "Active Date",
      "Termination Date",
      "DS Rate Class",
    ];

    keyHeaders = [
      "ownerName",
      "owner",
      "energizeDate",
      "subscriberName",     
      "customerIdentifier",
      "subscribeQuantity",
      "effectiveDate",
      "terminationDate",
      "rateClassDesc",
    ];

    this.reportData.forEach((row: any) => {
      let temp: any = {};
      temp["ownerName"] = row.ownerName;
      temp["owner"] = row.owner;
      temp["energizeDate"] = row.effectiveDate;
      temp["subscriberName"] = row.subscriberName;     
      temp["customerIdentifier"] = row.customerIdentifier;
      temp["subscribeQuantity"] = row.subscribeQuantity;
      temp["effectiveDate"] = row.effectiveDate;
      temp["terminationDate"] = row.terminationDate;
      temp["rateClassDesc"] = row.rateClassDesc;
      modifiedlist.push(temp);
    });
    this._export.exportJsonToExcelFile(
      modifiedlist,
      "ipa generation unit subscriber contract report",
      false,
      keyHeaders,
      displayHeaders
    );
  }
  ngOnDestroy() {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }
}
